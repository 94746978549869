class CommissionsPermissions {
    #permissions

    constructor({ show = true, update = true } = {}) {
        this.#permissions = {}
        this.show = show
        this.update = update
    }

    set show(state) {
        this.#permissions.show = state

        if (state !== true) {
            this.#permissions.update = false
        }
    }

    get show() {
        return this.#permissions.show
    }

    set update(state) {
        if (this.#permissions.show !== true) {
            this.#permissions.update = false
            return
        }

        this.#permissions.update = state
    }

    get update() {
        return this.#permissions.update
    }
}

export default CommissionsPermissions
