export const STEPS = {
    ACCIDENT_FORM: 'Nuevo siniestro',
    DRIVER_FORM: 'Datos del conductor',
    THIRD_PARTIES_FORM: 'Datos de terceros (otros vehículos involucrados)',
    DOCS_FORM: 'Carga de archivos',
    REVIEW_FORM: 'Revisión',
    EXTENSION_FORM: 'Crear ampliación'
};
  
export const STATUS = {
    DRAFT: "draft",
    SENT: "sent",
    SENDING: "sending",
    PENDING_ORDER: "pendingOrder",
    PENDING_INSPECTION: "pendingInspection",
    IN_REPAIR: "inRepair",
    NEW_EXTENSION: "newExtension",
    REJECTED: "rejected",
    DISCARDED: "discarded",
    PAYED: "payed",
    WITHOUT_RESPONSIBILITY: "withoutResponsibility",
    WITHOUT_ANSWER: "withoutAnswer"
};

export const RISK_TYPES = {
    CAR: "car",
    MOTO: "moto",
    MOTORCYCLE: "motorcycle"
};
  
export const VEHICLE_ACCIDENT_TYPE = {
    CRYSTALS: "crystals",
    LOCKS: "locks",
    WHEEL: "wheel",
    CRASH: "crash",
    CRASH_WITH_INJURIES: "crashWithInjuries"
};