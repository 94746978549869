import CommercialTermsPermissions from 'models/Permissions/AbstractPermissions/CommercialTermsPermissions'
import SelfManagementPlatformPermissions from 'models/Permissions/AbstractPermissions/SelfManagementPlatformPermissions'

class ProfilePermissions {
    constructor({ enabled = false, show = true, commercialTerms = {}, selfManagementPlatformLogo = {} } = {}) {
        this.enabled = enabled
        this.show = show
        this.commercialTerms = new CommercialTermsPermissions(commercialTerms)
        this.selfManagementPlatformLogo = new SelfManagementPlatformPermissions(selfManagementPlatformLogo)
    }
}

export default ProfilePermissions
