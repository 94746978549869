import axios from 'axios'
import { API_URL } from 'services/config'

class AccidentService {
    getUserManual = async() => {
        const { data } = await axios.get(`${API_URL}/commons/contact-us-data`)
        const { manual } = data.data
        return manual
    }

    getInsuranceCompanies = async() => {
        const { data } = await axios.get(`${API_URL}/insurance-companies`)
        const insuranceCompanies = data.data.map(({ name: id, displayName: value}) => ({ id, value }))
        return insuranceCompanies
    }

    getAccidentTypes = async() => {
        const { data } = await axios.get(`${API_URL}/accidents/vehicle-accident-types`)
        const accidentTypes = data.data.map(({ name: id, displayName: value}) => ({ id, value }))
        return accidentTypes
    }

    getInsuredPolicies = async(identityNumber) => {
        const { data } = await axios.get(`${API_URL}/accidents/options?identityNumber=${identityNumber}`)
        const res = data.data.map(({ description: value, policyId, accidentId }) => ({ value, policyId, accidentId }))
        return res
    }

    getAccident = async(accident) => {
        // console.log('AccidentService.getAccident(accident): ', accident)
        const { identityNumber, policyId, accidentId } = accident
        const res = await axios.get(`${API_URL}/accidents?identityNumber=${identityNumber}&policyId=${policyId}&accidentId=${accidentId}`)
        return res
    }

    saveAccident = async(accident) => {
        const res = await axios.post(`${API_URL}/accidents`, accident)
        return res
    }

    sendDocuments = async(documents) => {
        const { accidentId } = documents
        const res = await axios.post(`${API_URL}/accidents/${accidentId}/files`, documents)
        return res
    }

    addExtension = async(extension) => {
        const { accidentId } = extension
        const res = await axios.post(`${API_URL}/accidents/${accidentId}/extensions`, extension)
        return res
    }
}

export default AccidentService
