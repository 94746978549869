class ClusterCustomer {
    constructor(
        id,
        age,
        birthday,
        email,
        firstName,
        lastName,
        displayName,
        dni,
        cuil,
        gender,
        locality,
        phoneType,
        phoneNumber,
        flat,
        floor,
        streetName,
        streetNumber
    ) {
        this.id = id
        this.age = age
        this.birthday = birthday
        this.email = email
        this.firstName = firstName
        this.lastName = lastName
        this.displayName = displayName
        this.fullName = `${this.firstName} ${this.lastName}`
        this.dni = dni
        this.cuil = cuil
        this.gender = gender
        this.locality = locality
        this.phoneType = phoneType
        this.phoneNumber = phoneNumber
        this.flat = flat
        this.floor = floor
        this.streetName = streetName
        this.streetNumber = streetNumber
    }

    static fromJson({
        id,
        age,
        birthday,
        email,
        name,
        lastName,
        displayName,
        identityNumber,
        singleTaxIdentification,
        gender,
        locality,
        phoneType,
        phoneNumber,
        flat,
        floor,
        streetName,
        streetNumber,
    }) {
        return new ClusterCustomer(
            id,
            age,
            birthday,
            email,
            name,
            lastName,
            displayName,
            identityNumber,
            singleTaxIdentification,
            gender,
            locality,
            phoneType,
            phoneNumber,
            flat,
            floor,
            streetName,
            streetNumber
        )
    }

    fromStorage({ name, lastName }) {
        this.name = name
        this.lastName = lastName
    }
}

export default ClusterCustomer
