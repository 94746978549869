import { useAuthStore } from 'providers/AuthStoreProvider'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import Error503 from 'scenes/Error503'

const PrivateRoute = ({ component: Component, redirectPath, verifyPath, ...rest }) => {
    const authStore = useAuthStore()

    if (authStore.apiOffline) {
        return <Error503 />
    }

    if (authStore.isAuthenticated && authStore.isActivated) {
        return <Route {...rest} render={(props) => <Component {...props} />} />
    }

    if (authStore.isAuthenticated && !authStore.isActivated) {
        return (
            <Redirect
                to={{
                    pathname: verifyPath,
                }}
            />
        )
    }

    return (
        <Redirect
            to={{
                pathname: redirectPath,
            }}
        />
    )
}

PrivateRoute.propTypes = {
    redirectPath: PropTypes.string.isRequired,
    verifyPath: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
}

export default observer(PrivateRoute)
