import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const MetaTitle = ({ title }) => {
    const { t } = useTranslation()

    return (
        <Helmet>
            <title>{`${title} | ${t('siteName')}`}</title>
        </Helmet>
    )
}

MetaTitle.propTypes = {
    title: PropTypes.string.isRequired,
}

export default MetaTitle
