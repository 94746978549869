import { observable, runInAction, action, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import BadgesService from 'services/BadgesService'

class BadgesStore extends AsyncStore {
    notificationsCountUnread = 0
    comunicationsCountUnread = 0

    constructor() {
        super()

        makeObservable(this, {
            // observable
            notificationsCountUnread: observable,
            comunicationsCountUnread: observable,
            // action
            getBadges: action,
        })

        this.badgesService = new BadgesService()
        this.getBadges()
    }

    async getBadges() {
        this.preRequest(this.getBadges)
        try {
            const {
                notificationsCountUnread,
                comunicationsCountUnread,
            } = await this.badgesService.getBadges()

            runInAction(() => {
                this.notificationsCountUnread = notificationsCountUnread
                this.comunicationsCountUnread = comunicationsCountUnread
                this.onSuccessRequest()
            })
        } catch (e) {
            runInAction(() => {
                this.onErrorRequest(e)
            })
        }
    }
}

export default BadgesStore
