class AdvancedConfigOption {
    constructor(id, name) {
        this.id = id
        this.name = name
    }

    static fromJson({ id, name }) {
        return new AdvancedConfigOption(id, name)
    }

    fromStorage({ id, name }) {
        this.id = id
        this.name = name
    }
}

export default AdvancedConfigOption
