import ShowDataItem from 'models/ShowDataItem'

class VehicleCoverage {
    constructor(
        name,
        insuranceCompany,
        subscriptionAmount,
        commission,
        commissionAmount,
        companyLogo,
        smallCompanyLogo,
        coverageId,
        emission,
        shortName,
        longName
    ) {
        this.name = name
        this.insuranceCompany = insuranceCompany
        this.subscriptionAmount = subscriptionAmount
        this.commission = commission
        this.commissionAmount = commissionAmount
        this.companyLogo = companyLogo
        this.smallCompanyLogo = smallCompanyLogo
        this.coverageId = coverageId
        this.emission = emission
        this.shortName = shortName
        this.longName = longName
    }

    setDetail(detail) {
        const formattedData = []
        Object.keys(detail).forEach((key) => {
            formattedData.push(ShowDataItem.fromJson({ key, value: detail[key] }))
        })
        this.detail = formattedData
    }

    setQuoteId(quoteId) {
        this.id = quoteId
    }

    static fromJson({
        insuranceCompany,
        cluster,
        budget,
        insuranceCompanyLogos,
        quote,
        id,
        emission,
        shortName,
        longName,
    }) {
        const vehicleCoverage = new VehicleCoverage(
            cluster.description,
            insuranceCompany,
            budget.subscriptionAmount,
            budget.commission,
            budget.commissionAmount,
            insuranceCompanyLogos && insuranceCompanyLogos.logo,
            insuranceCompanyLogos && insuranceCompanyLogos.small,
            id,
            emission,
            shortName,
            longName
        )

        if (quote?.id) {
            vehicleCoverage.setQuoteId(quote.id)
        }

        if (budget.showData) {
            vehicleCoverage.setDetail(budget.showData)
        }

        return vehicleCoverage
    }
}

export default VehicleCoverage
