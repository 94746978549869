import VehicleCoverage from 'models/VehicleCoverage'

class VehicleCluster {
    constructor(id, name, coverage = null) {
        this.id = id
        this.name = name
        this.coverage = coverage
    }

    setCoverage(coverage) {
        this.coverage = coverage
    }

    static fromJson({
        insuranceCompany,
        cluster,
        budget,
        insuranceCompanyLogos,
        quote,
        id,
        emission,
        shortName,
        longName,
    }) {
        const vehicleCluster = new VehicleCluster(
            cluster.code,
            cluster.name,
            VehicleCoverage.fromJson({
                insuranceCompany,
                cluster,
                budget,
                insuranceCompanyLogos,
                quote,
                id,
                emission,
                shortName,
                longName,
            })
        )

        return vehicleCluster
    }
}

export default VehicleCluster
