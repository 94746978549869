import axios from 'axios'
import { API_URL } from 'services/config'

// import platformsMock from 'mocks/CustomerSelfManagement/platforms.json'
// import policyMock from 'mocks/CustomerSelfManagement/policy.json'
// import inspectionMock from 'mocks/CustomerSelfManagement/inspection.json'

class CustomerSelfManagementService {
    handleError = (error) => {
        if (error?.response?.data?.error?.message) {
            const errorMsg = error.response.data.error.message;
            throw new Error(errorMsg);
        } else {
            throw new Error('Error inesperado, intente nuevamente.');
        }
    }

    getPlatforms = async() => {
        try {
            const { data: { data: { platforms } } } = await axios.get(`${API_URL}/self-management-platforms`)

            return platforms
            // return platformsMock.platforms
        } catch (error) {
            return this.handleError(error)
        }
    }

    checkCustomer = async(identityNumber, metatitle) => {
        try {
            await axios.get(`${API_URL}/policy-preview?identityNumber=${identityNumber}&platform=${metatitle}`)

            return true
            // return policyMock
        } catch (error) {
            return this.handleError(error)
        }
    }

    getPolicy = async(customer, metatitle) => {
        try {
            const { identityNumber, plate } = customer
            const { data } = await axios.get(`${API_URL}/policy-preview?identityNumber=${identityNumber}&plate=${plate}&platform=${metatitle}`)
            
            return data
        } catch (error) {
            return this.handleError(error)
        }
    }

    getInspectionId = async(params) => {
        try {
            const { identityNumber, plate, policyId } = params
            const { data } = await axios.get(`${API_URL}/inspections?identityNumber=${identityNumber}&plate=${plate}&policyId=${policyId}`)
            
            return data
        } catch (error) {
            return this.handleError(error)
        }
    }

    getInspection = async(id) => {
        try {
            const { data } = await axios.get(`${API_URL}/inspections?inspectionId=${id}`)

            return data
        } catch (error) {
            return this.handleError(error)
        }
    }

    uploadInspection = async(inspection) => {
        try {
            await new Promise((resolve) => { setTimeout(resolve, 1300) })
            await axios.post(`${API_URL}/inspections?inspectionId=${inspection?.inspectionId}`, inspection)
            
            return true
        } catch (error) {
            return this.handleError(error)
        }
    }

    uploadBrokerSettings = async(settings) => {
        try {
            await axios.post(`${API_URL}/self-management-platforms`, settings)
            
            return true
        } catch (error) {
            return this.handleError(error)
        }
    }
}

export default CustomerSelfManagementService
