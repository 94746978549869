import ClientsPermissions from './ClientsPermissions'
import DashboardPermissions from './DashboardPermissions'
import PolicyPermissions from './PolicyPermissions'
import PolicyDetailsPermissions from './PolicyDetailsPermissions'
import QuoteCreationPermissions from './QuoteCreationPermissions'
import CurrentAccountPermissions from './CurrentAccountPermissions'
import CommercialConditions from './CommercialConditions'
import ProfilePermissions from './ProfilePermissions'
import CommunicationsPermissions from './CommunicationsPermissions'
import CommissionsPermissions from './CommissionsPermissions'

class ScenesPermissions {
    constructor({
        clients = {},
        dashboard = {},
        policy = {},
        policyDetails = {},
        quoteCreation = {},
        currentAccount = {},
        commercialConditions = {},
        profile = {},
        comunications = {},
        commissions = {},
    }) {
        this.clients = new ClientsPermissions(clients)
        this.dashboard = new DashboardPermissions(dashboard)
        this.policy = new PolicyPermissions(policy)
        this.policyDetails = new PolicyDetailsPermissions(policyDetails)
        this.quoteCreation = new QuoteCreationPermissions(quoteCreation)
        this.currentAccount = new CurrentAccountPermissions(currentAccount)
        this.commercialConditions = new CommercialConditions(commercialConditions)
        this.profile = new ProfilePermissions(profile)
        this.comunications = new CommunicationsPermissions(comunications)
        this.commissions = new CommissionsPermissions(commissions)
    }
}

export default ScenesPermissions
