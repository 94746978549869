import NeedHelpStore from 'stores/NeedHelpStore'
import HotKeyStore from 'stores/HotKeyStore'
import PermissionsStore from 'stores/PermissionsStore'
import BadgesStore from 'stores/BadgesStore'

class RootStore {
    constructor(authStore) {
        this.authStore = authStore
        this.hotKeyStore = new HotKeyStore()
        this.needHelpStore = new NeedHelpStore()
        this.badgesStore = new BadgesStore()

        const { authUser } = authStore
        this.permissionsStore = new PermissionsStore(authUser?.permissions)
    }
}

export default RootStore
