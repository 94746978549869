import { useContext } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import NotFound404 from 'scenes/NotFound404'
import { CUSTOMER_SELF_MANAGEMENT } from 'routing/routes'
import { CustomerSelfManagementStoreContext } from 'providers/PublicStoreProvider'

import styles from './publicMainLayout.module.scss'

const PublicMainLayout = ({ header: Header, children, footer: Footer }) => {
    const history = useHistory()
    
    const customerSelfManagementStore = useContext(CustomerSelfManagementStoreContext)
    const { platform, logo } = customerSelfManagementStore

    const goBack = () => {
        customerSelfManagementStore.resetStore()

        if (!platform) {
            window.location.replace(`${CUSTOMER_SELF_MANAGEMENT}/pasap`);
        } else {
            history.replace(`${CUSTOMER_SELF_MANAGEMENT}/${platform}`)
        }
    }

    return (
        <div className={styles.publicWrapper}>
            <div className={styles.publicHeader}>
                <Header platform={platform} goBack={goBack} logo={logo} />
            </div>

            <main className={styles.publicMain}>{ platform ? children : <NotFound404 /> }</main>
            
            <div className={styles.publicFooter}>
                <Footer platform={platform} />
            </div>
        </div>
    )
}

PublicMainLayout.propTypes = {
    header: PropTypes.oneOfType([PropTypes.object]).isRequired,
    footer: PropTypes.oneOfType([PropTypes.object]).isRequired,
    children: PropTypes.node.isRequired,
}

export default observer(PublicMainLayout)
