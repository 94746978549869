import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import moment from 'moment'
import i18n from 'util/i18n'
import parseJWT from 'util/parseJWT'
import { STILL_ALIVE_MODAL_DIFF_TIME, LOGOUT_DIFF_TIME } from 'services/config'
import AuthService from 'services/AuthService'
import AsyncStore from 'stores/AsyncStore'
import { setUser, clearUser } from 'util/sentry'
import { warningToast } from 'presentation/Toast/Toast'

class AuthStore extends AsyncStore {
    authUser = null
    stillAliveModal = false
    apiOffline = false
    logoutToast = null
    lastUserInteraction = null
    updatingBroker = false
    logoutTimeout
    stillAliveTimeout

    constructor() {
        super()
        this.isLoading = false
        this.accessToken = AuthService.getAccessToken()
        this.makeObservables()
        this.listenAccessToken()
    }

    setAuthUser(user) {
        this.authUser = user

        if (!user) {
            clearUser()
            return
        }

        setUser(user)
    }

    setApiOffline() {
        this.apiOffline = true
    }

    setUpdatingBroker(updating) {
        this.updatingBroker = updating
    }

    setAccessToken(token) {
        this.accessToken = token
    }

    /** logs out the user if token expires during session time * */
    setLogoutTimer() {
        clearTimeout(this.logoutTimeout)

        // logs out the user once the token expires
        this.logoutTimeout = setTimeout(() => {
            // this.logout(true)
        }, this.getTimeToExpiration(this.accessToken) - LOGOUT_DIFF_TIME)
    }

    /** shows still alive modal if token is close to expiring * */
    setStillAliveTimer() {
        clearTimeout(this.stillAliveTimeout)

        // logs out the user once the token expires
        this.stillAliveTimeout = setTimeout(() => {
            // this.checkUserStillAlive()
        }, this.getTimeToExpiration(this.accessToken) - STILL_ALIVE_MODAL_DIFF_TIME)
    }

    /** verifies if token is valid or expired * */
    checkTokenStatus(token) {
        if (this.getTimeToExpiration(token) <= 0) {
            return 'expired'
        }

        return 'valid'
    }

    /** returns remaining time for token expiration * */
    // eslint-disable-next-line
    getTimeToExpiration(token) {
        try {
            const {
                payload: { exp },
            } = parseJWT(token)

            if (typeof exp !== 'number') {
                throw new Error('invalid JWT expiration value')
            }

            const expTimeDate = moment(exp * 1000)

            const remaningExpTimeDate = expTimeDate.diff()

            return remaningExpTimeDate
        } catch (e) {
            return 0
        }
    }

    updateToken(token) {
        if (this.authUser) {
            this.authUser.updateToken(token)
            this.resetSettings()
        }
    }

    authenticate(authUser, token) {
        this.setAuthUser(authUser)
        this.setAccessToken(token)
        AuthService.persistAccessToken(token)
        this.setLogoutTimer()
        this.setStillAliveTimer()
    }

    resetSettings() {
        this.setLogoutTimer()
        this.setStillAliveTimer()

        if (this.logoutToast) {
            this.logoutToast.hide()

            setTimeout(() => {
                this.logoutToast = null
            })
        }
    }

    showStillAliveModal() {
        this.stillAliveModal = true
    }

    hideStillAliveModal() {
        this.stillAliveModal = false
    }

    keepAlive() {
        this.lastUserInteraction = moment()
    }

    checkUserStillAlive() {
        // revisa si hubo interacción en los últimos 3 minutos
        if (!this.lastUserInteraction || moment().diff(this.lastUserInteraction, 'minutes') >= 3) {
            this.showStillAliveModal()
        } else {
            this.refreshToken()
        }
    }

    can(permission) {
        return permission === 'yes' || this.authUser.permissions.includes(permission)
    }

    listenAccessToken() {
        window.addEventListener('storage', async (e) => {
            if (e.key === 'access_token' && e.oldValue && !e.newValue) {
                try {
                    if (this.isAuthenticated) {
                        this.setAuthUser(null)
                    }
                    // eslint-disable-next-line no-shadow,no-empty
                } catch (e) {}
            }
        })
    }

    get isAuthenticated() {
        return this.authUser !== null && this.checkTokenStatus(this.accessToken) !== 'expired'
    }

    get isActivated() {
        return this.authUser && this.authUser.isActivated
    }

    activateUser() {
        this.authUser.activate()
    }

    get isValidToken() {
        return this.accessToken || this.checkTokenStatus(this.accessToken) !== 'expired'
    }

    static async build(token) {
        const newAuthStore = new AuthStore()

        if (token) {
            await newAuthStore.loginWithToken(token)
        } else {
            await newAuthStore.loadAuthUser()
        }

        return newAuthStore
    }

    async basicLogin(username, password) {
        const { authUser, token } = await AuthService.authenticate(username, password)

        this.authenticate(authUser, token)

        if (this.logoutToast) {
            this.logoutToast.hide()

            setTimeout(() => {
                this.logoutToast = null
            })
        }
    }

    async refreshToken() {
        this.preRequest()

        const accessToken = await AuthService.refreshToken()

        runInAction(() => {
            this.onSuccessRequest()

            if (accessToken) {
                this.updateToken(accessToken)
            }

            this.hideStillAliveModal()
            this.keepAlive()
        })
    }

    async updateAuthUser() {
        this.preRequest()
        try {
            const authUser = await this.fetchAuthUserData()

            runInAction(() => {
                this.setAuthUser(authUser)
                this.onSuccessRequest()
            })
        } catch (e) {
            runInAction(() => {
                this.onErrorRequest(e)
            })
        }
    }

    async loadAuthUser() {
        this.preRequest()

        const authUser = await this.fetchAuthUserData(this.accessToken)

        if (!authUser) {
            await this.logout()
            this.onSuccessRequest()
            return null
        }

        this.keepAlive()
        this.authenticate(authUser, this.accessToken)
        this.onSuccessRequest()
        return authUser
    }

    async fetchAuthUserData(token) {
        this.setUpdatingBroker(true)

        if (!this.isValidToken) {
            return null
        }

        try {
            const authUser = await AuthService.fetchAuthUserData(token)

            runInAction(() => {
                this.setUpdatingBroker(false)
            })

            return authUser
        } catch (e) {
            runInAction(() => {
                this.setUpdatingBroker(false)
            })
            return null
        }
    }

    async loginWithToken(token) {
        if (!token) {
            throw new Error('need to pass a valid jwt token')
        }

        if (AuthService.getAccessToken()) {
            const message = 'Ya existe una sesión activa. ¿Deseas cerrarla y continuar?'

            // eslint-disable-next-line no-alert
            if (!window.confirm(message)) {
                throw new Error('Login with token canceled')
            }

            await this.logout()
        }

        this.setAccessToken(token)
        AuthService.persistAccessToken(token)

        await this.loadAuthUser()
    }

    async logout(timeout) {
        await AuthService.logout()

        this.hideStillAliveModal()
        this.setAuthUser(null)

        if (timeout) {
            this.logoutToast = warningToast(
                i18n.t('stillAlive:logoutToast'),
                { autoClose: false },
                'loggedOut'
            ).show()
        }
    }

    get permissions() {
        return this.authUser.permissions
    }

    makeObservables() {
        makeObservable(this, {
            // observables
            authUser: observable,
            stillAliveModal: observable,
            updatingBroker: observable,
            accessToken: observable,
            apiOffline: observable,
            // actions
            setAuthUser: action,
            setUpdatingBroker: action,
            basicLogin: action,
            setStillAliveTimer: action,
            setAccessToken: action,
            updateToken: action,
            resetSettings: action,
            keepAlive: action,
            refreshToken: action,
            logout: action,
            checkUserStillAlive: action,
            showStillAliveModal: action,
            hideStillAliveModal: action,
            updateAuthUser: action,
            loadAuthUser: action,
            fetchAuthUserData: action,
            listenAccessToken: action,
            activateUser: action,
            setApiOffline: action,
            // computeds
            isAuthenticated: computed,
            permissions: computed,
        })
    }
}

export default AuthStore
