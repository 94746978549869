import moment from 'moment'
import InputStore from 'stores/InputStore'

export default class DateInputStore extends InputStore {
    constructor(validationType) {
        super(validationType)

        this.value = null
    }

    setValue(value) {
        let dateValue = value

        if (dateValue === '') {
            dateValue = null
        }

        if (typeof dateValue === 'string') {
            dateValue = moment(value)
        }

        this.value = dateValue

        super.clearError()
    }
}
