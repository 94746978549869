export const NOVALIDATION = 'novalidation'
export const REQUIRED = 'required'
export const REQUIREDSELECT = 'requiredSelect'
export const EMAIL = 'email'
export const PHONE = 'phone'
export const DNI = 'dni'
export const REQUIRED_DNI = 'requiredDni'
export const BIRTH_DATE = 'birthDate'
export const REQUIRED_BIRTH_DATE = 'requiredBirthDate'
export const DATE = 'date'
export const REQUIRED_DATE = 'requireddate'
export const CBU_ALIAS_VALIDATION = 'cbuAlias'
export const REQUIRED_CBU_ALIAS_VALIDATION = 'requiredCbuAlias'
export const CBU_VALIDATION = 'cbu'
export const REQUIRED_CBU_VALIDATION = 'requiredCbu'
export const CC_VALIDATION = 'cc'
export const REQUIRED_CC_VALIDATION = 'requiredCc'
export const LICENSE_PLATE = 'licensePlate'
export const LICENSE_PLATE_NULLABLE = 'licensePlateNullableSchema'
export const CUIT = 'cuit'
export const REQUIRED_CUIT = 'requiredCuit'
export const CUIL = 'cuil'
export const REQUIRED_CUIL = 'requiredCuil'
export const EXPIRATION_DATE = 'expirationDate'
export const ACCIDENT_DATE = 'accidentDate'
export const REQUIRED_DNI_CUIL = 'requiredCuilOrDniSchema'
export const CAPTCHA = 'requiredCaptcha'
export const CUSTOMER_SELF_PLATFORM_URL = 'requiredCustomerSelfManagementPlatformURL'
