import { action, makeObservable, observable } from 'mobx'
import Validation from 'models/Validation'
import ErrorStore from 'stores/ErrorStore'

const INITIAL_VALUES = {
    value: '',
}

export default class InputStore {
    error = false
    errorMessage = null

    constructor(validationType, initialValues = INITIAL_VALUES) {
        this.value = initialValues.value
        this.initialValues = initialValues
        // eslint-disable-next-line no-underscore-dangle
        this._error = new ErrorStore()
        this.validation = new Validation(validationType)
        this.makeObservable()
    }

    setError(error) {
        this.error = true
        if (typeof error === 'string') {
            this.errorMessage = error
        } else {
            this.errorMessage = error?.message ?? 'Unknown error'
        }
        // eslint-disable-next-line no-underscore-dangle
        this._error.set(error)
    }

    setValue(value) {
        this.value = value || ''
        this.clearError()
        // eslint-disable-next-line no-underscore-dangle
        this._error.clear()
    }

    clearError() {
        this.error = false
        this.errorMessage = ''
        // eslint-disable-next-line no-underscore-dangle
        this._error.clear()
    }

    clearAll() {
        this.error = false
        this.errorMessage = null
        // eslint-disable-next-line no-underscore-dangle
        this._error.clear()
    }

    reset() {
        this.error = this.initialValues.error
        this.errorMessage = this.initialValues.errorMessage
        this.value = this.initialValues.value
        // eslint-disable-next-line no-underscore-dangle
        this._error.clear()
    }

    changeValidationType(validationType) {
        this.validation = new Validation(validationType)
    }

    async validate() {
        await this?.validation?.validate(this)
    }

    makeObservable() {
        makeObservable(this, {
            // observables
            error: observable,
            _error: observable,
            value: observable,
            errorMessage: observable,
            initialValues: observable,
            // actions
            setError: action,
            setValue: action,
            clearError: action,
            clearAll: action,
            reset: action,
            validate: action,
        })
    }
}
