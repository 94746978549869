// PUBLIC ROUTES
export const CUSTOMER_SELF_MANAGEMENT = '/autogestion'
export const CUSTOMER_SELF_MANAGEMENT_CLIENTS = '/clientes'
export const CUSTOMER_SELF_MANAGEMENT_DOCUMENTS = '/documentos'
export const CUSTOMER_SELF_MANAGEMENT_ACCIDENTS = '/siniestros'
export const CUSTOMER_SELF_MANAGEMENT_ACCIDENTS_DETAIL = '/siniestros/:id'
export const CUSTOMER_SELF_MANAGEMENT_PAYMENT_METHOD = '/pagos'
export const CUSTOMER_SELF_MANAGEMENT_POLICY_DATA = '/datos'
export const CUSTOMER_SELF_MANAGEMENT_INSPECTIONS = '/inspecciones/:id'

// PRIVATE ROUTES
export const CLIENTS = '/clientes'
export const CLIENT_CREATION = `${CLIENTS}/nuevo`
export const CLIENT_DETAIL = `${CLIENTS}/detalle/:id`
export const COMMERCIAL_CONDITIONS = '/comerciales'
export const COMMERCIAL_CONDITIONS_DETAIL = '/comerciales/detalle'
export const COMMERCIAL_CONDITIONS_DETAIL_COVERAGE = '/comerciales/detalle/covertura'
export const COMUNICATIONS = '/comunicaciones'
export const COMUNICATION_DETAIL = '/comunicaciones/:id'
export const CURRENT_ACCOUNT = '/cuenta_corriente'
export const DASHBOARD = '/dashboard'
export const EMIT_CONSORTIUM_POLICY_ADDITIONAL_DATA = '/poliza/emitir/adicional'
export const EMIT_CONSORTIUM_POLICY_PAYMENT_DATA = '/poliza/emitir/pago'
export const EMIT_HOME_POLICY = '/hogar/emitir'
export const EMIT_MARKET_POLICY_ADDITIONAL_DATA = '/poliza/emitir/adicional'
export const EMIT_MARKET_POLICY_PAYMENT_DATA = '/poliza/emitir/pago'
export const EMIT_POLICY_ADDITIONAL = '/poliza/emitir/adicional'
export const EMIT_POLICY_PAYMENT_DATA = '/poliza/emitir/pago'
export const EMIT_POLICY_REDIRECT = '/poliza/emitir'
export const FORGOT_PASSWORD = '/registro/recuperar-contrasena'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const NOTIFICATIONS = '/notifications'
export const POLICIES = '/polizas'
export const POLICY_DETAIL = '/polizas/detalle/:id'
export const POLICY_CREATE = '/polizas/nueva'
export const POLICY_CREATE_INITIAL = '/polizas/nueva/datos'
export const POLICY_CREATE_CLIENT = '/polizas/nueva/cliente'
export const POLICY_CREATE_DATA = '/polizas/nueva/descripcion'
export const POLICY_CREATE_PAYMENT = '/polizas/nueva/pago'
export const POLICY_CREATE_ASSET = '/polizas/nueva/bien'
export const POLICY_MODIFY = '/polizas/editar/:id'
export const POLICY_MODIFY_INITIAL = '/polizas/editar/:id/datos'
export const POLICY_MODIFY_CLIENT = '/polizas/editar/:id/cliente'
export const POLICY_MODIFY_DATA = '/polizas/editar/:id/descripcion'
export const POLICY_MODIFY_PAYMENT = '/polizas/editar/:id/pago'
export const POLICY_MODIFY_ASSET = '/polizas/editar/:id/bien'
export const POLICY_ENDORSEMENT_DETAIL = '/poliza/endosos/detalle/:id'
export const POLICY_ENDORSEMENT_PETITION = '/poliza/endosos/solicitud/:id/'
export const POLICY_ENDORSEMENT_PETITION_CREATION = '/poliza/endosos/creacion/solicitud/:id'
export const POLICY_ENDORSEMENTS = '/poliza/endosos/:id'
export const POLICY_ENDORSEMENTS_MODIFICATIONS = '/poliza/endosos/modificaciones/:id'
export const PROFILE = '/perfil'
export const QUOTES = '/cotizaciones'
export const QUOTES_CREATION = '/cotizaciones/creacion'
export const QUOTES_CREATION_CAR = `${QUOTES_CREATION}/auto`
export const QUOTES_CREATION_CAR_BRAND = `${QUOTES_CREATION_CAR}/marca`
export const QUOTES_CREATION_CAR_CLIENT = `${QUOTES_CREATION_CAR}/clients`
export const QUOTES_CREATION_CAR_COVERAGE = `${QUOTES_CREATION_CAR}/coverages/:id`
export const QUOTES_CREATION_CAR_LOCATION = `${QUOTES_CREATION_CAR}/location`
export const QUOTES_CREATION_CAR_SEND = `${QUOTES_CREATION_CAR}/enviar`
export const QUOTES_CREATION_CAR_SUMMARY = `${QUOTES_CREATION_CAR}/resumen`
export const QUOTES_CREATION_CAR_VERSION = `${QUOTES_CREATION_CAR}/version`
export const QUOTES_CREATION_CONSORTIUM = `${QUOTES_CREATION}/consortium`
export const QUOTES_CREATION_CONSORTIUM_COVERAGES = `${QUOTES_CREATION}/consortium/coberturas`
export const QUOTES_CREATION_CONSORTIUM_QUOTES = `${QUOTES_CREATION}/consortium/cotizaciones`
export const QUOTES_CREATION_CONSORTIUM_SUMMARY = `${QUOTES_CREATION}/consortium/resumen`
export const QUOTES_CREATION_EMIT_POLICY = `${QUOTES_CREATION_CAR}/emitir-poliza`
export const QUOTES_CREATION_ERROR = `${QUOTES_CREATION_CAR}/error`
export const QUOTES_CREATION_HOME = `${QUOTES_CREATION}/hogar`
export const QUOTES_CREATION_HOME_COVERAGES = `${QUOTES_CREATION}/hogar/coberturas`
export const QUOTES_CREATION_HOME_QUOTES = `${QUOTES_CREATION}/hogar/cotizaciones`
export const QUOTES_CREATION_HOME_SUMMARY = `${QUOTES_CREATION}/hogar/resumen`
export const QUOTES_CREATION_MARKET = `${QUOTES_CREATION}/comercio`
export const QUOTES_CREATION_MARKET_COVERAGES = `${QUOTES_CREATION}/comercio/coberturas`
export const QUOTES_CREATION_MARKET_QUOTES = `${QUOTES_CREATION}/comercio/cotizaciones`
export const QUOTES_CREATION_MARKET_SUMMARY = `${QUOTES_CREATION}/comercio/resumen`
export const QUOTES_CREATION_MOTO = `${QUOTES_CREATION}/moto`
export const QUOTES_CREATION_MOTO_BRAND = `${QUOTES_CREATION_MOTO}/marca`
export const QUOTES_CREATION_MOTO_CLIENT = `${QUOTES_CREATION_MOTO}/cliente`
export const QUOTES_CREATION_MOTO_EMISSION = `${QUOTES_CREATION_MOTO}/emitir-poliza`
export const QUOTES_CREATION_MOTO_EMISSION_CLIENT = `${QUOTES_CREATION_MOTO_EMISSION}/cliente`
export const QUOTES_CREATION_MOTO_EMISSION_INSURED = `${QUOTES_CREATION_MOTO_EMISSION}/asegurado`
export const QUOTES_CREATION_MOTO_EMISSION_MOTO = `${QUOTES_CREATION_MOTO_EMISSION}/auto`
export const QUOTES_CREATION_MOTO_EMISSION_PAYMENT = `${QUOTES_CREATION_MOTO_EMISSION}/pago`
export const QUOTES_CREATION_MOTO_LOCATION = `${QUOTES_CREATION_MOTO}/localización`
export const QUOTES_CREATION_MOTO_MULTIQUOTE = `${QUOTES_CREATION_MOTO}/cotización/:id`
export const QUOTES_CREATION_MOTO_SUMMARY = `${QUOTES_CREATION_MOTO}/resumen`
export const QUOTES_CREATION_MOTO_VERSION = `${QUOTES_CREATION_MOTO}/version`
export const QUOTES_CREATION_POLICY_CAR = `${QUOTES_CREATION_EMIT_POLICY}/auto`
export const QUOTES_CREATION_POLICY_CLIENT = `${QUOTES_CREATION_EMIT_POLICY}/cliente`
export const QUOTES_CREATION_POLICY_INSURED = `${QUOTES_CREATION_EMIT_POLICY}/asegurado`
export const QUOTES_CREATION_POLICY_PAYMENT = `${QUOTES_CREATION_EMIT_POLICY}/pago`
export const QUOTES_CREATION_SENT = `${QUOTES_CREATION_CAR}/poliza-enviada`
export const REGISTER = '/registro'
export const REGISTER_FULL = '/onboarding'
export const REGISTER_FULL_DOCUMENTS = '/onboarding/full/documentación'
export const REGISTER_FULL_TERMS = '/onboarding/full/términos'
export const REGISTER_HELP = '/contacto'
export const REGISTER_PROFESSIONAL = '/onboarding/matricula'
export const REGISTER_VALIDATE = `${REGISTER}/validación`
export const REGISTER_VERIFY = `${REGISTER}/verificación`
export const RESET_PASSWORD = '/registro/nueva-contrasena'
export const SETTINGS = '/settings'
export const SUCCESSFUL_CREATION_CLIENT = `${CLIENTS}/creado`
export const SUCCESSFUL_EDIT_CLIENT = `${CLIENTS}/editado`
