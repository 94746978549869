import { observable, makeObservable, action } from 'mobx'
import InputStore from 'stores/InputStore'
import { DNI, LICENSE_PLATE_NULLABLE } from 'util/validationMapping'

class ThirdParties {
    constructor() {
        this.identityNumber = new InputStore(DNI)
        this.fullName = new InputStore()
        this.address = new InputStore()
        this.insuredCompany = new InputStore()
        this.plate = new InputStore(LICENSE_PLATE_NULLABLE)
        this.carBrandModel = new InputStore()

        makeObservable(this, {
            // observables
            identityNumber: observable,
            fullName: observable,
            address: observable,
            insuredCompany: observable,
            plate: observable,
            carBrandModel: observable,
            // actions
            setIdentityNumber: action,
            setFullName: action,
            setAddress: action,
            setInsuredCompany: action,
            setPlate: action,
            setCarBrandModel: action,
        })
    }

    setIdentityNumber(value) {
        this.identityNumber.setValue(value)
    }

    setFullName(value) {
        this.fullName.setValue(value)
    }

    setAddress(value) {
        this.address.setValue(value)
    }

    setInsuredCompany(value) {
        this.insuredCompany.setValue(value)
    }

    setPlate(value) {
        this.plate.setValue(value)
    }

    setCarBrandModel(value) {
        this.carBrandModel.setValue(value)
    }

    clearErrors() {
        this.identityNumber?.clearError()
        this.fullName?.clearError()
        this.address?.clearError()
        this.insuredCompany?.clearError()
        this.plate?.clearError()
        this.carBrandModel?.clearError()
    }

    async validate() {
        await this.identityNumber?.validate()
        await this.fullName?.validate()
        await this.address?.validate()
        await this.insuredCompany?.validate()
        await this.plate?.validate()
        await this.carBrandModel?.validate()
    }

    json() {
        return {
            identityNumber: this.identityNumber?.value,
            fullName: this.fullName?.value,
            address: this.address?.value,
            insuredCompany: this.insuredCompany?.value.id ? this.insuredCompany?.value.id : '',
            plate: this.plate?.value,
            carBrandModel: this.carBrandModel?.value
        }
    }

    fromAPI(data) {
        this.setIdentityNumber(data?.identityNumber)
        this.setFullName(data?.fullName)
        this.setAddress(data?.address)
        this.setInsuredCompany(data?.insuredCompany)
        this.setPlate(data?.plate)
        this.setCarBrandModel(data?.carBrandModel)

        setTimeout(() => this.clearErrors(), 1)
    }
}

export default ThirdParties
