import axios from 'axios'
import { API_URL } from 'services/config'
import Help from 'models/Help'

class NeedHelpService {
    getHelpData = () =>
        axios.get(`${API_URL}/commons/contact-us-data`).then(({ data: { data } }) => {
            return Help.fromJson(data)
        })
}

export default NeedHelpService
