import MultiquoteCarPermissions from './MultiquoteCarPermissions'
import MultiquoteHomePermissions from './MultiquoteHomePermissions'
import MultiquoteCommercePermissions from './MultiquoteCommercePermissions'
import MultiquoteConsortiumPermissions from './MultiquoteConsortiumPermissions'
import MultiquoteMotorbikePermissions from './MultiquoteMotorbikePermissions'

class MultiquotePermissions {
    constructor({ car, motorbike, home, commerce, consortium }) {
        this.car = new MultiquoteCarPermissions(car)
        this.motorbike = new MultiquoteMotorbikePermissions(motorbike)
        this.home = new MultiquoteHomePermissions(home)
        this.commerce = new MultiquoteCommercePermissions(commerce)
        this.consortium = new MultiquoteConsortiumPermissions(consortium)
    }
}

export default MultiquotePermissions
