class Help {
    constructor(email, phone, whatsapp, feedback) {
        this.email = email
        this.phone = phone
        this.whatsapp = whatsapp
        this.feedback = feedback
    }

    static fromJson({ email, phone, whatsapp, feedback }) {
        return new Help(email, phone, whatsapp, feedback)
    }
}

export default Help
