import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { SentryInit } from './util/sentry'
import App from './App'

SentryInit()

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_TAG_MANAGER_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
    }
    try {
        TagManager.initialize(tagManagerArgs)
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Cant initialize gtm module')
    }
}

ReactDOM.render(<App />, document.getElementById('root'))
