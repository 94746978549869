import { action, observable, makeObservable, runInAction } from 'mobx'
import i18next from 'i18next'
import AsyncStore from 'stores/AsyncStore'
import AccidentService from 'services/AccidentService/AccidentService'
import Accident from 'models/Accident'
import Insured from 'models/Accident/Insured'
import Driver from 'models/Accident/Driver'
import ThirdParties from 'models/Accident/ThirdParties'
import { STEPS, RISK_TYPES, VEHICLE_ACCIDENT_TYPE, STATUS } from 'util/accidentConstants'

class AccidentStore extends AsyncStore {
    constructor() {
        super()
        this.accidentService = new AccidentService() 

        this.accident = new Accident()
        this.insured = new Insured()
        this.driver = new Driver()
        this.thirdParties = new ThirdParties()

        this.accidentTypes = null
        this.insuranceCompanies = null
        this.accidentOptions = []
        this.accidentOptionsLoading = false
        this.accidentSelected = { policyId: null, accidentId: null, value: null }
        this.entryFormExtraInputs = false

        this.userManualURL = ''
        this.filesToSend = []

        this.makeObservables()
    }

    async getInsuranceCompanies() {
        this.insuranceCompanies = await this.accidentService.getInsuranceCompanies()
    }

    async getAccidentTypes() {
        this.accidentTypes = await this.accidentService.getAccidentTypes()
    }

    async getAccidentOptions(identityNumber) {
        this.setAccidentOptionsLoading(true)
        const options = await this.accidentService.getInsuredPolicies(identityNumber)
        this.setAccidentOptions(options)
        this.setAccidentOptionsLoading(false)
    }

    async initializeAccident() {
        this.preRequest(this.initializeAccident)
        await this.insured.validate()  

        if (this.insured.identityNumber.error) {
            this.finishRequest()
            return
        }

        try {
            this.setAccident()

            const { identityNumber } = this.insured.json()
            const { policyId, accidentId } = this.accidentSelected
            const { data } = await this.accidentService.getAccident({ identityNumber, policyId, accidentId })

            this.fromAPI(data?.data) 
            
            runInAction(() => {
                this.onSuccessRequest()
            })
        } catch (e) {
            if (e.response?.status === 409) {
                this.setEntryFormExtraInputs(true)
            } else {
                this.setEntryFormExtraInputs(false)
            }

            this.handleErrors(e)
        }
    }

    async updateAccident(step) {
        this.preRequest(this.updateAccident)

        // #userMauro: validadores de formularios
        try {
            switch (step) {
                case STEPS.ACCIDENT_FORM:
                    await this.accident.validate()
                    if (this.formAccidentHasError) {
                        this.finishRequest()
                        return false
                    }
                    break;
                case STEPS.DRIVER_FORM:
                    await this.driver.validate()
                    if (this.formDriverHasError) {
                        this.finishRequest()
                        return false
                    }
                    break;
                case STEPS.THIRD_PARTIES_FORM:
                    await this.thirdParties.validate()
                    if (this.formThirdPartiesHasError){
                        this.finishRequest()
                        return false
                    }
                    break;
                case STEPS.DOCS_FORM: {
                    const files = this.accident.filesForm?.files.map((file) => {
                        if (file.file !== null) {
                            return { filename: file.file?.name, content: file.fileBase64 }
                        }
                        return null
                    })
                        .filter((document) => document !== null);

                    this.setFilesToSend(files)
                    this.accident.setDocuments(files.length)
                    break;
                }
                case STEPS.REVIEW_FORM:
                    await this.accident.validate()
                    if (this.accident.email.error) {
                        this.finishRequest()
                        return false
                    }

                    await this.accidentService.sendDocuments({
                        context: 'accident',
                        identityNumber: this.insured?.identityNumber?.value,
                        accidentId: this.accident.id,
                        files: this.filesToSend
                    })

                    if (!this.accident.isSent) {
                        this.accident.setStatus( {id: STATUS.SENDING, value: "Enviando a Pasap"} );
                    }
                    break;
                case STEPS.EXTENSION_FORM: {
                    await this.accident.validate()
                    if (this.accident.extensionNote.error) {
                        this.finishRequest()
                        return false
                    }

                    const extensionFiles = this.accident.extensionsFilesForm?.files.map((file) => {
                        if (file.file !== null) {
                            return { filename: file.file?.name, content: file.fileBase64 }
                        }
                        return null
                    })
                        .filter((document) => document !== null);

                    await this.accidentService.addExtension({
                        identityNumber: this.insured?.identityNumber?.value,
                        accidentId: this.accident.id,
                        note: this.accident.extensionNote?.value,
                        files: extensionFiles,
                    })
                    break;
                }
                default:
                    break;
            }

            this.setAccident()
            const { data } = await this.accidentService.saveAccident(this.accident.json())
            
            if (this.accident.id === null) {
                this.accident.setIdAccident(data?.data.id)
            }

            runInAction(() => {
                this.onSuccessRequest()
                this.finishRequest()
            })

            return true
        } catch (e) {
            return this.handleErrors(e)
        }
    }

    setAccident() {
        this.accident.setInsured(this.insured)
        this.accident.setDriver(this.driver)
        this.accident.setThirdParties(this.thirdParties)
    }

    setThirdParties(value) {
        this.thirdParties = value
    }

    setEntryFormExtraInputs(value) {
        this.entryFormExtraInputs = value
    }

    setUserManualURL(value) {
        this.userManualURL = value
    }

    setInsuredAsDriver() {
        if (this.driver.isInsured) {
            this.driver = new Driver()
            this.setAccident()
        } else {
            const { birthDate, fullName, identityNumber } = this.insured
            
            this.driver.setBirthDate(birthDate.value)
            this.driver.setFullName(fullName.value)
            this.driver.setIdentityNumber(identityNumber.value)
            this.driver.isInsured = true
            this.setAccident()
        }
    }

    resetAccidentOptions() {
        this.accidentOptions = []
        this.accidentSelected = { policyId: null, accidentId: null, value: null }
    }

    setAccidentSelected(accident) {
        this.accidentSelected = accident
    }

    setFilesToSend(files) {
        this.filesToSend = files
    }

    setAccidentOptions(value) {
        this.accidentOptions = value
    }

    setAccidentOptionsLoading(value) {
        this.accidentOptionsLoading = value
    }

    async getUserManualURL() {
        this.preRequest(this.getUserManualURL)

        try {
            const response = await this.accidentService.getUserManual()

            runInAction(() => {
                this.setUserManualURL(response)
                this.onSuccessRequest()
            })
        } catch (e) {
            runInAction(() => {
                this.onErrorRequest(e)
            })
        }
    }

    async handleErrors(e) {
        if (e.response?.status === 404) {
            this.onErrorRequest(i18next.t('accidents:error404'))
        } else if (e.response?.status === 409) {
            this.onErrorRequest(e.response?.data?.error.message)
            await this.getAccidentOptions(this.insured.identityNumber?.value)
        } else if (e.response?.status === 400) {
            this.onErrorRequest(e.response?.data?.error.message)
        } else if (e.response?.status === 500) {
            this.onErrorRequest(i18next.t('accidents:error500'))
        } else if (e.status === 401) {
            this.onErrorRequest('')
        } else {
            this.onErrorRequest(i18next.t('accidents:genericError'))
        }

        this.finishRequest()
        return false
    }

    get isVehicle() {
        return (
            this.accident?.riskType?.id === RISK_TYPES.CAR ||
            this.accident?.riskType?.id === RISK_TYPES.MOTO ||
            this.accident?.riskType?.id === RISK_TYPES.MOTORCYCLE
        )
    }

    get hasThirdParties() {
        return (
            this.accident?.vehicleAccidentType?.value?.id === VEHICLE_ACCIDENT_TYPE.CRASH_WITH_INJURIES ||
            this.accident?.vehicleAccidentType?.value?.id === VEHICLE_ACCIDENT_TYPE.CRASH
        )
    }

    
    get imagesUploaded() {
        return (
            this.accident.documents?.images?.length
        )
    }

    get pdfsUploaded() {
        return (
            this.accident.documents?.pdfs?.length
        )
    }

    get extensionImagesUploaded() {
        return (
            this.accident.newExtension?.documents?.images?.length
        )
    }

    get extensionPdfsUploaded() {
        return (
            this.accident.newExtension?.documents?.pdfs?.length
        )
    }

    get hasDocumentsUploaded() {
        return (
            this.accident.documents > 0
        )
    }

    get hasReplacementLocation() {
        return (
            this.accident.vehicleAccidentType?.value?.id === VEHICLE_ACCIDENT_TYPE.CRYSTALS ||
            this.accident.vehicleAccidentType?.value?.id === VEHICLE_ACCIDENT_TYPE.LOCKS ||
            this.accident.vehicleAccidentType?.value?.id === VEHICLE_ACCIDENT_TYPE.WHEEL
        )
    }

    get formAccidentHasError() {
        return (
            this.accident.date.error ||
            this.accident.time.error ||
            this.accident.description.error ||
            this.accident.place.error ||
            this.accident.damages.error ||
            (this.isVehicle && this.accident.vehicleAccidentType.error)
        )
    }

    get formDriverHasError() {
        return (
            this.driver.identityNumber.error ||
            this.driver.fullName.error
        )
    }

    get formThirdPartiesHasError() {
        return (
            this.thirdParties.identityNumber.error ||
            this.thirdParties.plate.error
        )
    }

    json() {
        return {
            accident: this.accident?.json(),
            insured: this.insured?.json(),
            driver: this.driver?.json(),
            thirdParties: this.thirdParties?.json(),
        }
    }

    fromAPI(data) {
        this.accident.fromAPI(data)
        this.insured.fromAPI(data.insured)
        this.driver.fromAPI(data.driver)
        this.thirdParties.fromAPI(data.thirdParties)
        // (data.driver && this.driver.fromAPI(data.driver))
        // (data.thirdParties && this.thirdParties.fromAPI(data.thirdParties))
    }

    reset() {
        this.accident = new Accident();
        this.insured = new Insured();
        this.driver = new Driver();
        this.thirdParties = new ThirdParties();
    }

    makeObservables() {
        makeObservable(this, {
            // observables
            accident: observable,
            insured: observable,
            driver: observable,
            thirdParties: observable,
            accidentTypes: observable,
            insuranceCompanies: observable,
            entryFormExtraInputs: observable,
            accidentOptions: observable,
            accidentSelected: observable,
            userManualURL: observable,
            accidentOptionsLoading: observable,
            // actions
            initializeAccident: action,
            getInsuranceCompanies: action,
            getAccidentTypes: action,
            reset: action,
            setInsuredAsDriver: action,
            setEntryFormExtraInputs: action,
            setAccidentOptions: action,
            getAccidentOptions: action,
            resetAccidentOptions: action,
            setAccidentSelected: action,
            setUserManualURL: action,
            setAccidentOptionsLoading: action,
        })
    }
}

export default AccidentStore
