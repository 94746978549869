class JWTError extends Error {
    name = 'JWTError'
}

export default function parseJWT(token, base64Signature = false) {
    if (!/^.+?\..+?\..+$/.test(token)) {
        throw new JWTError('malformed JWT')
    }

    const [header, payload, signature] = token.split('.')

    const jwtData = {}

    try {
        const decodedHeader = atob(header)
        jwtData.header = JSON.parse(decodedHeader)
    } catch (e) {
        throw new JWTError('malformed JWT header')
    }

    try {
        const decodedPayload = atob(payload)
        jwtData.payload = JSON.parse(decodedPayload)
    } catch (e) {
        throw new JWTError('malformed JWT payload')
    }

    if (base64Signature) {
        try {
            const decodedSignature = atob(signature)
            jwtData.signature = JSON.parse(decodedSignature)
        } catch (e) {
            throw new JWTError('malformed JWT signature')
        }

        return jwtData
    }

    return { ...jwtData, signature }
}
