import Permissions from 'models/Permissions'

class PermissionsDto extends Map {
    static fromJson(dataFromAPI) {
        const permissions = new PermissionsDto(dataFromAPI.map(({ name, value }) => [name, value]))

        return new Permissions({
            scenes: {
                profile: {
                    commercialTerms: {
                        show: permissions.get('commissions.show'),
                    },
                    selfManagementPlatformLogo: {
                        update: permissions.get('broker.logo.update')
                    }
                },
                dashboard: {
                    show: permissions.get('dashboard.show'),
                    commissions: {
                        show: permissions.get('commissions.show'),
                    },
                    carousel: {
                        show: permissions.get('carousel.show'),
                    },
                    manual: {
                        show: permissions.get('broker.manual.show')
                    }
                },
                clients: {
                    brokerParent: {
                        show: permissions.get('brokerParent.show'),
                    },
                    organizer: {
                        show: permissions.get('organizer.show'),
                    },
                    commissions: {
                        show: permissions.get('commissions.show'),
                    },
                },
                quoteCreation: {
                    commissions: {
                        show: permissions.get('commissions.show'),
                        update: permissions.get('commissions.update'),
                    },
                    multiquote: {
                        car: {
                            enabled: permissions.get('multiquote.car.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        motorbike: {
                            enabled: permissions.get('multiquote.motorcycle.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        home: {
                            enabled: permissions.get('multiquote.home.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        commerce: {
                            enabled: permissions.get('multiquote.commerce.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        consortium: {
                            enabled: permissions.get('multiquote.consortium.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        life: {
                            enabled: permissions.get('multiquote.life.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        guaranteeInsurance: {
                            enabled: permissions.get('multiquote.guaranteeInsurance.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        civilResponsibility: {
                            enabled: permissions.get('multiquote.civilResponsibility.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        transport: {
                            enabled: permissions.get('multiquote.transport.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        others: {
                            enabled: permissions.get('multiquote.others.quote'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                    },
                    emissions: {
                        car: {
                            enabled: permissions.get('emissions.car.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        motorcycle: {
                            enabled: permissions.get('emissions.motorcycle.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        home: {
                            enabled: permissions.get('emissions.home.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        commerce: {
                            enabled: permissions.get('emissions.commerce.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        consortium: {
                            enabled: permissions.get('emissions.consortium.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        life: {
                            enabled: permissions.get('emissions.life.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        guaranteeInsurance: {
                            enabled: permissions.get('emissions.guaranteeInsurance.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        civilResponsibility: {
                            enabled: permissions.get('emissions.civilResponsibility.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        transport: {
                            enabled: permissions.get('emissions.transport.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                        others: {
                            enabled: permissions.get('emissions.others.emit'),
                            commissions: {
                                show: permissions.get('commissions.show'),
                                update: permissions.get('commissions.update'),
                            },
                        },
                    },
                },
                policy: {
                    manual: {
                        show: permissions.get('policies.manual.show'),
                        create: permissions.get('policies.manual.create'),
                        update: permissions.get('policies.manual.update'),
                        remove: permissions.get('policies.manual.delete'),
                    },
                },
                policyDetails: {
                    commissions: {
                        show: permissions.get('commissions.show'),
                    },
                },
                currentAccount: {
                    show: permissions.get('dashboard.show'),
                    commissions: {
                        show: permissions.get('commissions.show'),
                    },
                },
                commercialConditions: {
                    show: permissions.get('dashboard.show'),
                },
                comunications: {
                    show: permissions.get('comunications.show'),
                },
                commissions: {
                    show: permissions.get('commissions.show'),
                    update: permissions.get('commissions.update'),
                },
            },
        })
    }
}

export default PermissionsDto
