import MultiquotePermissions from 'models/Permissions/AbstractPermissions/MultiquotePermissions'
import EmissionPermissions from 'models/Permissions/AbstractPermissions/EmissionPermissions'

class QuoteCreationPermissions {
    constructor({ multiquote = {}, emissions = {} } = {}) {
        this.multiquote = new MultiquotePermissions(multiquote)
        this.emissions = new EmissionPermissions(emissions)
    }
}

export default QuoteCreationPermissions
