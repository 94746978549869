import axios from 'axios'
import { API_URL } from 'services/config'
import VehicleCluster from 'models/VehicleCluster'
import ClusterCustomer from 'models/ClusterCustomer'
import VehicleQuoteCreationData from 'models/VehicleQuoteCreationData'

const REQUEST_INTERVAL_TIME =
    Number(process.env.REACT_APP_REQUEST_MULTI_QUOTE_INTERVAL_TIME) || 5000

class RequiringCoveragesService {
    static interval = null

    static clearInterval() {
        if (RequiringCoveragesService.interval) {
            clearInterval(RequiringCoveragesService.interval)
            RequiringCoveragesService.interval = null
        }
    }

    static processData({
        data: {
            multiQuoteRequestStatus,
            properties,
            multiQuoteType,
            customer,
            broker = {},
            validFrom,
            multiQuoteStatus,
            lastEmissionError,
            emission,
            commissionType,
            coverages = [],
        },
    }) {
        const { locality, year, infoAutoData } = properties
        const { brand, model } = infoAutoData
        const customerFullName = customer.name + customer.lastName

        const vehicleQuoteCreationData = VehicleQuoteCreationData.fromJson(
            multiQuoteType,
            brand,
            model,
            year,
            customerFullName,
            locality,
            broker.onBoardingLevel,
            validFrom
        )

        const clusterArray = coverages.map((cluster) => VehicleCluster.fromJson(cluster))

        const clusterCustomer = ClusterCustomer.fromJson(customer)

        return {
            multiQuoteRequestStatus,
            clusterArray,
            clusterCustomer,
            vehicleQuoteCreationData,
            multiQuoteStatus,
            lastEmissionError,
            emission,
            commissionType,
        }
    }

    static getMultiQuoteStatus(id, onMessage) {
        return axios.get(`${API_URL}/multi-quotes/${id}`).then(({ data }) => {
            const response = RequiringCoveragesService.processData(data)
            onMessage(response)
            return response.multiQuoteRequestStatus
        })
    }

    static async requireCoverages(id, onMessage) {
        RequiringCoveragesService.clearInterval()

        const firstMultiQuoteRequestStatus = await RequiringCoveragesService.getMultiQuoteStatus(
            id,
            onMessage
        )

        if (firstMultiQuoteRequestStatus !== 'ended') {
            RequiringCoveragesService.interval = setInterval(async () => {
                const multiQuoteRequestStatus = await RequiringCoveragesService.getMultiQuoteStatus(
                    id,
                    onMessage
                )
                // si el backend ha terminado el rastreo finalizo intervalo
                if (multiQuoteRequestStatus === 'ended') {
                    RequiringCoveragesService.clearInterval()
                }
            }, REQUEST_INTERVAL_TIME)
        }
    }
}

export default RequiringCoveragesService
