import { memo } from 'react'
import PropTypes from 'prop-types'
import logoPasap from 'assets/png/logoPasap.png'

import styles from './publicAppLogo.module.scss'

const PublicAppLogo = ({ logo }) => {
    return <img src={logo !== null ? logo : logoPasap} alt="" className={styles.logo} />
}

PublicAppLogo.defaultProps = {
    logo: null,
}

PublicAppLogo.propTypes = {
    logo: PropTypes.string,
}

export default memo(PublicAppLogo)
