import uuid from 'react-uuid'
import { observable, makeObservable } from 'mobx'

import { REQUIRED } from 'util/validationMapping'
import FileInputStore from 'stores/FileInputStore'
import { INSPECTION_STATUS } from 'stores/CustomerSelfManagementStore'

class InspectionPhoto {
    constructor() {
        this.file = new FileInputStore({ 
            acceptedTypeList: ['image/jpeg', 'image/png', 'application/pdf'], 
            maxFileSize: 5242880, 
            supportedFormats: '*.jpg, *.png, *.pdf', 
            id: uuid() }, 
        REQUIRED)

        this.tag = { id: null, value: null}
        this.fileStatus = { id: INSPECTION_STATUS.PENDING, value: "Pendiente"}

        makeObservable(this, {
            // observables
            file: observable,
            tag: observable,
            fileStatus: observable,
        })
    }

    setContent(value) {
        this.content = value
    }

    setTag(value) {
        this.tag = value
    }

    setStatus(value) {
        this.status = value
    }

    clearErrors() {
        this.file?.clearError()
    }

    async validate() {
        this.clearErrors()
        await this.content?.validate()
    }
}

export default InspectionPhoto
