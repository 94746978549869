import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import accidents from 'lang/accidents.json'
import amendmentPetitions from 'lang/amendmentPetitions.json'
import appError from 'lang/appError.json'
import appRefresh from 'lang/appRefresh.json'
import clients from 'lang/clients.json'
import common from 'lang/common.json'
import comunications from 'lang/comunications.json'
import currentAccount from 'lang/currentAccount.json'
import customerSelfManagement from 'lang/customerSelfManagement.json'
import dashboard from 'lang/dashboard.json'
import dynamicQuoteCreation from 'lang/dynamicQuoteCreation.json'
import emitPolicy from 'lang/emitPolicy.json'
import errors from 'lang/errors.json'
import header from 'lang/header.json'
import homeQuoteCreation from 'lang/homeQuoteCreation.json'
import location from 'lang/location.json'
import membership from 'lang/membership.json'
import notifications from 'lang/notifications.json'
import pageTitles from 'lang/pageTitles.json'
import policies from 'lang/policies.json'
import policyStatus from 'lang/policyStatus.json'
import quoteCreation from 'lang/quoteCreation.json'
import quotes from 'lang/quotes.json'
import register from 'lang/register.json'
import sidebar from 'lang/sidebar.json'
import signIn from 'lang/signIn.json'
import settings from 'lang/settings.json'
import stillAlive from 'lang/stillAlive.json'
import table from 'lang/table.json'
import { DEFAULTLANG, detectLang } from 'util/language'

i18n.use(initReactI18next).init({
    fallbackLng: DEFAULTLANG,
    lng: detectLang(),

    // have a common namespace used around the full app
    ns: [
        'accidents',
        'amendmentPetitions',
        'appError',
        'appRefresh',
        'clients',
        'common',
        'comunications',
        'currentAccount',
        'customerSelfManagement',
        'dashboard',
        'dynamicQuoteCreation',
        'emitPolicy',
        'errors',
        'header',
        'homeQuoteCreation',
        'location',
        'membership',
        'notifications',
        'pageTitles',
        'policies',
        'policyStatus',
        'quoteCreation',
        'quotes',
        'register',
        'sidebar',
        'signIn',
        'settings',
        'stillAlive',
        'table',
        'validation',
    ],
    defaultNS: 'common',

    debug: false,

    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    react: {
        wait: true,
        useSuspense: false,
    },

    resources: {
        es: {
            accidents,
            amendmentPetitions,
            appError,
            appRefresh,
            clients,
            common,
            comunications,
            currentAccount,
            customerSelfManagement,
            dashboard,
            dynamicQuoteCreation,
            emitPolicy,
            errors,
            header,
            homeQuoteCreation,
            location,
            membership,
            notifications,
            pageTitles,
            policies,
            policyStatus,
            quoteCreation,
            quotes,
            register,
            sidebar,
            signIn,
            settings,
            stillAlive,
            table,
        },
    },
})

export default i18n
