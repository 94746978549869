import EmissionCarPermissions from './EmissionCarPermissions'
import EmissionHomePermissions from './EmissionHomePermissions'
import EmissionCommercePermissions from './EmissionCommercePermissions'
import EmissionConsortiumPermissions from './EmissionConsortiumPermissions'
import EmissionMotorcyclePermissions from './EmissionMotorcyclePermissions'

class EmissionPermissions {
    constructor({ car, home, commerce, consortium, motorcycle } = {}) {
        this.car = new EmissionCarPermissions(car)
        this.home = new EmissionHomePermissions(home)
        this.commerce = new EmissionCommercePermissions(commerce)
        this.consortium = new EmissionConsortiumPermissions(consortium)
        this.motorcycle = new EmissionMotorcyclePermissions(motorcycle)
    }
}

export default EmissionPermissions
