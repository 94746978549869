import { memo } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './loadingRing.module.scss'

const LoadingRing = ({ small, center, justify, absolute, inverted }) => (
    <div
        className={c(
            styles.wrapper,
            center && styles.center,
            absolute && styles.absolute,
            justify === 'left' && styles.justifyLeft,
            justify === 'right' && styles.justifyRight
        )}
    >
        <div className={c(styles.loadingRing, small && styles.small, inverted && styles.inverted)}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
)

LoadingRing.propTypes = {
    small: PropTypes.bool,
    center: PropTypes.bool,
    absolute: PropTypes.bool,
    inverted: PropTypes.bool,
    justify: PropTypes.string,
}

LoadingRing.defaultProps = {
    small: false,
    center: false,
    absolute: false,
    inverted: false,
    justify: null,
}

export default memo(LoadingRing)
