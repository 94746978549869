import { lazy } from 'react'

function ComponentLoader(componentDynamicImport, triesLeft, timeout) {
    return new Promise((resolve, reject) => {
        componentDynamicImport()
            .then(resolve)
            .catch((error) => {
                if (triesLeft < 1) {
                    reject(error)
                    return
                }

                const timer = setTimeout(() => {
                    reject(error)
                }, timeout)

                ComponentLoader(componentDynamicImport, triesLeft - 1, timeout)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => clearTimeout(timer))
            })
    })
}

function lazyLoaderComponent(
    componentDynamicImport,
    onError,
    onLoad,
    triesLeft = 3,
    timeout = 300000
) {
    return (
        lazy(() =>
            ComponentLoader(componentDynamicImport, triesLeft, timeout)
                .then((component) => {
                    if (onLoad != null) {
                        onLoad(component)
                    }

                    return component
                })
                .catch((error) => {
                    if (onError != null) {
                        onError(error)
                    }

                    return Promise.reject(error)
                })
        ) || null
    )
}

export default lazyLoaderComponent
