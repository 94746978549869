import * as yup from 'yup'
import moment from 'moment'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

export const STRING_INPUT = 'string'
export const DATE_INPUT = 'date'
export const NUMBER_INPUT = 'number'

const dateStringToObject = (string) => {
    if (string === 'today') {
        return moment().startOf('day').toDate()
    }
    return undefined
}

const formatDate = (currentDate, format) => {
    return moment(currentDate).format(format)
}

/**
 * Crea un objeto Map con las reglas de validación.
 * Esto permite manipularlas: rules.has('required') o rules.get('max') devuelve el número o la fecha parseada
 * */
export const parseValidationRules = (validationRulesString) => {
    if (!validationRulesString) {
        return null
    }

    return validationRulesString
        .split('|')
        .filter((rule) => rule.trim() !== '')
        .reduce((validationMap, rule) => {
            // "validationRules": "required"
            if (rule === 'required') {
                validationMap.set('required', true)
                return validationMap
            }

            if (rule === 'novalidation') {
                validationMap.set('required', false)
                return validationMap
            }

            if (rule === 'email') {
                validationMap.set('email', true)
                return validationMap
            }

            // "validationRules": "min:now" || "validationRules": "min:255"
            const minMatches = rule.match(/min:(\w+)/)
            if (minMatches) {
                let min = Number(minMatches[1])
                // eslint-disable-next-line no-restricted-globals
                if (isNaN(minMatches[1])) {
                    min = dateStringToObject(minMatches[1])
                }

                validationMap.set('min', min)

                return validationMap
            }

            // "validationRules": "max:now" || "validationRules": "max:255"
            const maxMatches = rule.match(/max:(\w+)/)
            if (maxMatches) {
                let max = Number(maxMatches[1])
                // eslint-disable-next-line no-restricted-globals
                if (isNaN(maxMatches[1])) {
                    max = dateStringToObject(maxMatches[1])
                }
                validationMap.set('max', max)

                return validationMap
            }

            return validationMap
        }, new Map())
}

const getTextWithTranslation = (type, a) => {
    const keyToUse = type === 'min' ? `common:field_date_min` : `common:field_date_max`
    const date = formatDate(a, i18next.t('common:humanDateYearFormat'))
    return (
        <Trans i18nKey={keyToUse} key={keyToUse}>
            {{ date }}
        </Trans>
    )
}

export const createSchemaFromRules = (rules, dataType = STRING_INPUT) => {
    let validation = yup.string()
    let isInputDate = false

    if (dataType === DATE_INPUT) {
        validation = yup.date()
        isInputDate = true
    }

    if (dataType === NUMBER_INPUT) {
        validation = yup.number()
    }

    if (rules) {
        if (rules.get('required')) {
            validation = validation.required(i18next.t('field_required'))
        }

        if (rules.get('requiredSchema')) {
            validation = validation.required(i18next.t('field_required'))
        }

        if (rules.get('novalidation')) {
            validation = validation.notRequired()
        }

        if (rules.get('email')) {
            validation = validation.email(i18next.t('field_email'))
        }

        if (rules.get('min')) {
            validation = isInputDate
                ? validation.min(rules.get('min'), getTextWithTranslation('min', rules.get('min')))
                : validation.min(rules.get('min'))
        }

        if (rules.get('max')) {
            validation = isInputDate
                ? validation.max(rules.get('max'), getTextWithTranslation('max', rules.get('max')))
                : validation.max(rules.get('max'))
        }
    }

    return validation
}

export const countPartErrors = (part, inputs) => {
    const input = inputs.get(part.id)
    let errors = 0

    if (part.parts) {
        // Si tiene sub partes, las recorro

        part.parts.forEach((subPart) => {
            errors += countPartErrors(subPart, inputs)
        })
    }

    if (input) {
        // Si tiene un store de input, me fijo si es válido
        if (input.isValid) {
            return 0
        }

        return 1
    }

    return errors
}

export const validatePart = (part, inputs) => {
    const input = inputs.get(part.id)

    if (part.parts) {
        // Si tiene sub partes, las recorro

        part.parts.forEach((subPart) => {
            validatePart(subPart, inputs)
        })
    } else if (input) {
        input.validate()
    }
}

export const getPartErrorMessages = (part, inputs) => {
    const input = inputs.get(part.id)

    if (input) {
        if (!input.isValid) {
            return [
                {
                    label: part.label,
                    message: input.errorMessage || input.store.errorMessage,
                },
            ]
        }
    }

    let errors = []
    if (part.parts) {
        // Si tiene sub partes, las recorro
        part.parts.forEach((subPart) => {
            errors = [...errors, ...getPartErrorMessages(subPart, inputs)]
        })
    }

    return errors
}

export const partIsValid = (part, inputs) => {
    return !countPartErrors(part, inputs)
}

export function isValidCuit(cuit) {
    const strCuit = String(cuit)

    if (strCuit === '') {
        return true
    }

    if (strCuit.length !== 11) {
        return false
    }

    const vd = Number(strCuit.slice(-1))

    const verif = strCuit
        .substring(0, 10)
        .split('')
        .reverse()
        .reduce((acc, n, i) => acc + Number(n) * (2 + (i % 6)), 0)

    const cvd = (11 - (verif % 11)) % 11

    return cvd === vd
}

function verifyCBU(block = '', bm = []) {
    const blockList = block.split('')
    const blockVd = Number(blockList.pop())

    const blockCalc = blockList.reduce((acc, n, i) => acc + Number(n) * bm[i], 0)

    const blockCalcLastDigit = Number(blockCalc.toString().slice(-1))

    const verifDigit = 10 - blockCalcLastDigit

    if (blockVd === 0 && verifDigit === 10) {
        return true
    }

    if (blockVd === verifDigit) {
        return true
    }

    return false
}

export function isValidCBU(cbu) {
    if (cbu == null || cbu === '') {
        return true
    }

    if (typeof cbu !== 'string' && typeof cbu !== 'bigint') {
        throw new TypeError('The CBU parameter must be a string or BigInt.')
    }

    const strCBU = cbu.toString()

    if (strCBU.length !== 22) {
        return false
    }

    const bankBlock = strCBU.slice(0, 8)

    if (!verifyCBU(bankBlock, [7, 1, 3, 9, 7, 1, 3])) {
        return false
    }

    const accountBlock = strCBU.slice(8)

    if (!verifyCBU(accountBlock, [3, 9, 7, 1, 3, 9, 7, 1, 3, 9, 7, 1, 3])) {
        return false
    }

    return true
}

function checkLuhn(val = '') {
    const valList = val.split('')

    const parity = valList.length % 2

    const sumDigit = Number(valList.pop())

    const sum = valList.reduce((acc, d, i) => {
        let digit = Number(d)

        if (i % 2 === parity) {
            digit *= 2
        }

        if (digit > 9) {
            digit -= 9
        }

        return acc + digit
    }, sumDigit)

    return sum % 10 === 0
}

export function isValidCC(cc) {
    if (cc == null || cc === '') {
        return true
    }

    if (typeof cc !== 'string' && typeof cc !== 'number') {
        throw new TypeError('The Credit Card parameter must be a string or number.')
    }

    const strCC = cc.toString().replace(/\D+/g, '')

    if (strCC.length < 13 || strCC.length > 19) {
        return false
    }

    return checkLuhn(strCC)
}

export default createSchemaFromRules

export function objectIsEmpty(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}
