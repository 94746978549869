class Province {
    constructor(id, value, localities) {
        this.id = id
        this.value = value
        this.localities = localities
    }

    addLocality(locality) {
        this.localities.push(locality)
    }

    static fromJson(id, value, localities) {
        return new Province(id, value, localities)
    }
}

export default Province
