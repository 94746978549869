import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import PublicAppLogo from 'presentation/PublicAppLogo'
import { CUSTOMER_SELF_MANAGEMENT } from 'routing/routes'

import styles from './publicHeader.module.scss'

const PublicHeader = ({ platform, goBack, logo }) => {
    const platformLink = platform ? `${CUSTOMER_SELF_MANAGEMENT}/${platform}` : `${CUSTOMER_SELF_MANAGEMENT}/pasap`

    return (
        <div className={styles.publicHeaderContainer}>
            <Link to={platformLink} className={styles.publicAppLogoContainer} onClick={goBack}>
                <PublicAppLogo logo={logo} />
            </Link>
        </div>
    )
}

PublicHeader.propTypes = {
    platform: PropTypes.string,
    goBack: PropTypes.func.isRequired,
    logo: PropTypes.string
}

PublicHeader.defaultProps = {
    platform: null,
    logo: null,
}

export default observer(PublicHeader)
