import { useState, useRef, useCallback } from 'react'
import axiosEx from 'services/AxiosEx'

const AppHashValidation = () => {
    const browserAppHashRef = useRef(null)
    const [isOnSync, setIsOnSync] = useState(true)

    const init = useCallback(async () => {
        const { etag } = await axiosEx.headers('/')

        browserAppHashRef.current = browserAppHashRef.current || etag
    }, [])

    const validate = async () => {
        const { etag } = await axiosEx.headers('/')

        setIsOnSync(etag == null || etag === browserAppHashRef.current)
    }

    return [isOnSync, validate, init]
}

export default AppHashValidation
