import BaseScenesPermissions from 'models/Permissions/BaseScenesPermissions'
import CommissionsPermissions from 'models/Permissions/AbstractPermissions/CommissionsPermissions'

class PolicyDetailsPermissions extends BaseScenesPermissions {
    constructor({ show = true, enabled = true, commissions = {} } = {}) {
        super({ show, enabled })
        this.commissions = new CommissionsPermissions(commissions)
    }
}

export default PolicyDetailsPermissions
