import CloseIcon from 'mdi-react/CloseIcon'
import PropTypes from 'prop-types'
import style from './toast.module.scss'

const CloseButton = ({ closeToast }) => (
    <div className={style.closeButton} onClick={closeToast} role="button" tabIndex="0">
        <CloseIcon />
    </div>
)

CloseButton.propTypes = {
    closeToast: PropTypes.func.isRequired,
}

export default CloseButton
