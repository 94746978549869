class VehicleQuoteCreationData {
    constructor(
        multiQuoteType,
        brand,
        model,
        year,
        customerFullName,
        locality,
        onBoardingLevel,
        validFrom
    ) {
        this.multiQuoteType = multiQuoteType
        this.brand = brand
        this.model = model
        this.year = year
        this.customerFullName = customerFullName
        this.locality = locality
        this.onBoardingLevel = onBoardingLevel
        this.validFrom = validFrom
    }

    static fromJson(
        multiQuoteType,
        brand,
        model,
        year,
        customerFullName,
        locality,
        onBoardingLevel,
        validFrom
    ) {
        const carUserSelectedData = new VehicleQuoteCreationData(
            multiQuoteType,
            brand,
            model,
            year,
            customerFullName,
            locality,
            onBoardingLevel,
            validFrom
        )
        return carUserSelectedData
    }
}

export default VehicleQuoteCreationData
