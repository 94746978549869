import { createContext, useContext, useEffect, useState } from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import AuthStore from 'stores/AuthStore'
import axiosInterceptors from 'util/axiosInterceptors'
import { useHistory } from 'react-router'

const AuthStoreContext = createContext(null)

const AuthStoreProvider = ({ children }) => {
    const history = useHistory()
    const [authStore, setAuthStore] = useState(null)

    useEffect(() => {
        const token = qs.parse(history.location.search.slice(1))?.token
        AuthStore.build(token).then((newAuthStore) => {
            axiosInterceptors(newAuthStore)
            setAuthStore(newAuthStore)
        })
    }, [])

    if (!authStore) {
        return null
    }

    return <AuthStoreContext.Provider value={authStore}>{children}</AuthStoreContext.Provider>
}

AuthStoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

const useAuthStore = () => useContext(AuthStoreContext)

// eslint-disable-next-line import/prefer-default-export
export { AuthStoreProvider, useAuthStore }
