import { makeAutoObservable } from 'mobx'

class ErrorStore {
    errors

    constructor() {
        this.errors = []
        makeAutoObservable(this)
    }

    getAll() {
        return this.errors
    }

    get() {
        return this.errors.length ? this.errors[0] : undefined
    }

    get message() {
        return this.errors.length ? this.errors[0].message : undefined
    }

    clear() {
        this.errors = []
    }

    get exists() {
        return !!this.errors.length
    }

    // eslint-disable-next-line consistent-return
    push(error) {
        if (typeof error === 'object' && !(error instanceof Error)) {
            throw new Error('Should pass an Error instance')
        }

        if (typeof error === 'string') {
            return this.errors.push(new Error(error))
        }

        this.errors.push(error)
    }

    set(error) {
        this.clear()
        this.push(error)
    }
}

export default ErrorStore
