class VehicleBrand {
    constructor(id, brand) {
        this.id = id
        this.brand = brand
    }

    static fromJson({ id, name }) {
        return new VehicleBrand(id, name)
    }
}

export default VehicleBrand
