import MetaTitle from 'presentation/MetaTitle'
import { useTranslation } from 'react-i18next'
import EmoticonSadOutlineIcon from 'mdi-react/EmoticonSadOutlineIcon'
import styles from './notfound404.module.scss'

const NotFound404 = () => {
    const { t } = useTranslation('appError')

    return (
        <>
            <MetaTitle title={t('pageTitles:notFound')} />
            <div className={styles.container}>
                <EmoticonSadOutlineIcon className={styles.icon} />
                <div className={styles.title}>Error 404</div>
                <div className={styles.note}>{t('sorryPageNotFound')}</div>
            </div>
        </>
    )
}

export default NotFound404
