import i18next from 'util/i18n'

export const ON_BOARDING_LEVEL_ONE_PENDING = 'level-one--pending'
export const ON_BOARDING_LEVEL_ONE_REVIEW = 'level-one--pending-review'
export const ON_BOARDING_LEVEL_TWO_PENDING = 'level-two--pending'
export const ON_BOARDING_LEVEL_TWO_REVIEW = 'level-two--pending-review'
export const ON_BOARDING_FINISHED = 'finished'
export const ON_BOARDING_LEVEL_KAVAK = 'levelKavak'

export const IVA_OPTIONS = [
    { id: 'vatResposanbleRegistered', value: i18next.t('register:vatResposanbleRegistered') },
    { id: 'monotax', value: i18next.t('register:monotax') },
]

export const COMPANY_ALLIANZ_KEY = 'allianz'
export const COMPANY_ZURICH_KEY = 'zurich'
export const COMPANY_SURA_KEY = 'sura'
export const COMPANY_EXPERTA_KEY = 'experta'
export const COMPANY_MA_KEY = 'mercantilAndina'

export const EMIT_STATUS_WAITING = 'waiting'
export const EMIT_STATUS_NEEDS_REQUOTE = 'needsRequote'
export const EMIT_STATUS_EMITTED = 'emitted'
export const EMIT_STATUS_NEEDS_CONFIRMATION = 'needsConfirmation'

export const DEFAULT_COMMISSION_TYPE = 'standard'

export const VALIDATION_FIELD_ERROR = 'VALIDATION_FIELD_ERROR'

export const APP_RELEASE = process.env.REACT_APP_GIT_BRANCH?.split('/').pop()

export const APP_ENVIROMENT = process.env.NODE_ENV
