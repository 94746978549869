import { observable, computed, action, makeObservable } from 'mobx'
import i18next from 'i18next'

class AsyncStore {
    isLoading = false
    errors = []
    processedRequestErrors = []
    serverError = false

    constructor() {
        makeObservable(this, {
            // observables
            isLoading: observable,
            errors: observable,
            serverError: observable,
            processedRequestErrors: observable,
            // actions
            processRequestErrors: action,
            requestProcess: action,
            tryAgain: action,
            preRequest: action,
            onSuccessRequest: action,
            clearError: action,
            setServerError: action,
            finishRequest: action,
            onErrorRequest: action,
            addProcessedRequestError: action,
            // computeds
            hasErrors: computed,
        })
    }

    requestProcess(request = null) {
        this.clearError()
        this.tryAgainRequest = request
    }

    tryAgain() {
        if (this.tryAgainRequest) {
            this.tryAgainRequest()
        }

        return null
    }

    preRequest(request) {
        this.isLoading = true
        this.errors = []
        this.requestProcess(request)
    }

    onSuccessRequest() {
        this.isLoading = false
    }

    clearError() {
        this.serverError = false
        this.errors = []
        this.processedRequestErrors = []
    }

    setServerError() {
        this.serverError = true
    }

    finishRequest() {
        this.isLoading = false
    }

    onErrorRequest(error) {
        this.finishRequest()
        this.errors.push(error)

        if (AsyncStore.isServerError(error)) {
            this.setServerError(error)
        }
    }

    addProcessedRequestError(error) {
        this.processedRequestErrors.push(error)
    }

    processRequestErrors(e) {
        const message = e.response?.data?.error?.message || e.message

        if (typeof message === 'string') {
            this.addProcessedRequestError(message)

            return
        }

        if (message instanceof Object) {
            Object.values(message).forEach((value) => {
                if (typeof value === 'string') {
                    this.addProcessedRequestError(value)
                }

                if (value instanceof Array) {
                    this.addProcessedRequestError(value[0])
                }
            })

            return
        }

        this.addProcessedRequestError(i18next.t('common:genericRequestError'))
    }

    get hasErrors() {
        return this.errors?.flat().length > 0
    }

    static isServerError(error) {
        return error?.response?.status === 500
    }
}

export default AsyncStore
