import BaseScenesPermissions from 'models/Permissions/BaseScenesPermissions'
import PolicyManualPermissions from 'models/Permissions/ScenesPermissions/PolicyManualPermissions'

class PolicyPermissions extends BaseScenesPermissions {
    constructor({ show = true, enabled = true, manual = {} } = {}) {
        super({ show, enabled })
        this.manual = new PolicyManualPermissions(manual)
    }
}

export default PolicyPermissions
