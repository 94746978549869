import i18next from 'i18next'
import { CARD, CBU } from '../services/QuoteCreationService'

// phones
export const PHONETYPE_MOBILE = 'mobile'
export const PHONETYPE_LANDLINE = 'landLine'

// gender
export const GENDER_MALE = 'male'
export const GENDER_FEMALE = 'female'

// person type
export const PHYSICAL_PERSON = 'physical'
export const LEGAL_PERSON = 'legal'

// QUOTES
export const QUOTE_TYPE_CAR = 'car'
export const QUOTE_TYPE_MOTO = 'motorcycle'

// BUILDINGS
export const BUILDING_TYPE_HOME = 'BUILDING_TYPE_HOME'
export const BUILDING_TYPE_CONSORTIUM = 'BUILDING_TYPE_CONSORTIUM'
export const BUILDING_TYPE_MARKET = 'BUILDING_TYPE_MARKET'

// NATIONALITY
export const DEFAULT_COUNTRY = 'argentina'

// EMISSION STATUSES
export const EMISSION_STATUS_DRAFT = 'draft'
export const EMISSION_STATUS_PENDING = 'pending'
export const EMISSION_STATUS_REQUOTING = 'requoting'
export const EMISSION_STATUS_REQUIRES_CONFIRMATION = 'requires_confirmation'
export const EMISSION_STATUS_ERROR = 'error'
export const EMISSION_STATUS_IN_EMISSION = 'in_emission'
export const EMISSION_STATUS_ENDED = 'ended'
export const EMISSION_STATUS_EXPIRED = 'expired'

// MULTIQUOTE STATUSES
export const MULTIQUOTE_STATUS_ENDED = 'ended'
export const MULTIQUOTE_STATUS_EXPIRED = 'expired'

// NOTIFICATION TYPES
export const MULTIQUOTE_TYPE = 'multiQuote'
export const MULTIQUOTE_LIST_TYPE = 'multiQuoteList'
export const POLICY_TYPE = 'policy'
export const ONBOARDING_TYPE = 'onBoarding'
export const AMENDMENTPETITION_TYPE = 'amendmentPetition'
export const COMMUNICATION_TYPE = 'communication'

// MULTIQUOTE TABLE
export const MAXIMUM_COVERAGES_SEND = 4

// STORAGE KEYS
export const MOTO_STORAGE_KEY = 'motoStorageData'

// QUOTE CREATION STEPS
export const MOTO_QUOTE_CREATION_STEPS = 5

class CommonDataStore {
    static getPhoneTypeOptions() {
        return [
            { value: i18next.t(`quoteCreation:${PHONETYPE_MOBILE}`), key: PHONETYPE_MOBILE },
            { value: i18next.t(`quoteCreation:${PHONETYPE_LANDLINE}`), key: PHONETYPE_LANDLINE },
        ]
    }

    static getMultilateralAgreementOptions() {
        return [
            { name: i18next.t('register:yes'), id: 'Sí', key: true },
            { name: i18next.t('register:no'), id: 'No', key: false },
        ]
    }

    static getDefaultPhoneTypeOption() {
        return CommonDataStore.getPhoneTypeByKey(PHONETYPE_MOBILE)
    }

    static getPhoneTypeByKey(key) {
        const [selectedPhoneType] = CommonDataStore.getPhoneTypeOptions().filter(
            (phoneTypeOption) => phoneTypeOption.key === key
        )
        return selectedPhoneType
    }

    static getGenderOptions() {
        return [
            { name: i18next.t(`quoteCreation:${GENDER_MALE}`), id: GENDER_MALE },
            { name: i18next.t(`quoteCreation:${GENDER_FEMALE}`), id: GENDER_FEMALE },
        ]
    }

    static getPersonTypeOptions() {
        return [
            {
                title: `${i18next.t('clients:physicalPerson')} (alt+1)`,
                name: i18next.t('clients:physicalPerson'),
                id: PHYSICAL_PERSON,
            },
            {
                title: `${i18next.t('clients:legalPerson')} (alt+2)`,
                name: i18next.t('clients:legalPerson'),
                id: LEGAL_PERSON,
            },
        ]
    }

    static getDefaultPersonTypeOption() {
        return PHYSICAL_PERSON
    }

    static isLegalPerson(value) {
        return value === LEGAL_PERSON
    }

    static isPhysicalPerson(value) {
        return value === PHYSICAL_PERSON
    }

    static isCarQuote(value) {
        return value === QUOTE_TYPE_CAR
    }

    static isMotoQuote(value) {
        return value === QUOTE_TYPE_MOTO
    }

    static isVehicleQuote(value) {
        return value === QUOTE_TYPE_CAR || value === QUOTE_TYPE_MOTO
    }

    static getMinAge() {
        return 18
    }

    static getVehicleCommissionDefault() {
        return { id: 80, text: i18next.t('quoteCreation:standard'), value: 100, key: 'standard' }
    }

    static getPaymentOptions() {
        return [
            {
                title: `${i18next.t('quoteCreation:cbu')} (alt+1)`,
                name: i18next.t('quoteCreation:cbu'),
                id: CBU,
            },
            {
                title: `${i18next.t('quoteCreation:card')} (alt+2)`,
                name: i18next.t('quoteCreation:card'),
                id: CARD,
            },
        ]
    }
}

export default CommonDataStore
