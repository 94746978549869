import { toast } from 'react-toastify'
import CheckIcon from 'mdi-react/CheckIcon'
import AlertCircleIcon from 'mdi-react/AlertCircleIcon'
import InformationVariantIcon from 'mdi-react/InformationVariantIcon'
import moment from 'moment'
import style from './toast.module.scss'
import CloseButton from './CloseButton'

const updateToast = (id, content, opts = {}, type) => {
    let options = content ? Object.assign(opts, { render: content }) : {}
    if (type) {
        options = Object.assign(options, {
            type: toast.TYPE.INFO,
            className: [style.toast, style[`Toast--${type}`]].join(' '),
        })
    }

    toast.update(id, options)
}

const getContent = (type, content) => {
    return (
        <div className={style.toastContent}>
            {type !== toast.TYPE.INFO && (
                <div className={style.toastContentIcon}>
                    {type === toast.TYPE.SUCCESS && <CheckIcon />}
                    {type === toast.TYPE.ERROR && <AlertCircleIcon />}
                    {type === toast.TYPE.WARNING && <InformationVariantIcon />}
                </div>
            )}
            <span className={style.toastContentText}>{content}</span>
        </div>
    )
}

export default toast

export const Toast = (content = '', type = toast.TYPE.INFO, opts = {}, customId) => {
    const toastId = customId || `toast_${moment().unix()}`
    const resultObj = {
        id: toastId,
        type,
        showed: false,
    }

    // Configuracion default
    const options = {
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        toastId,
        autoClose: 4000,
        draggable: false,
        closeButton: <CloseButton />,
        position: toast.POSITION.TOP_CENTER,
        className: [style.toast, style[type]].join(' '),
        bodyClassName: style.toastBody,
        progressClassName: style.progressBar,
        onClose: () => {
            resultObj.showed = false
        },
        ...opts,
    }
    resultObj.show = () => {
        if (toast.isActive(toastId)) {
            toast.update(toastId, Object.assign(options, { render: getContent(type, content) }))
        } else {
            toast[type](getContent(type, content), options)
        }
        resultObj.showed = true
        return resultObj
    }

    resultObj.hide = () => {
        toast.dismiss(toastId)
        resultObj.showed = false
        return resultObj
    }

    // Actualizacion del toast
    resultObj.update = (newContent, newOptions = {}) => {
        updateToast(toastId, getContent(toast.TYPE.INFO, newContent), null, newOptions)
        return resultObj
    }

    // Actualiza el toast a tipo Info
    resultObj.toInfo = (newContent, newOptions = {}) => {
        updateToast(toastId, getContent(toast.TYPE.INFO, newContent), newOptions, toast.TYPE.INFO)
        return resultObj
    }

    // Actualiza el toast a tipo Error
    resultObj.toError = (newContent, newOptions = {}) => {
        updateToast(toastId, getContent(toast.TYPE.ERROR, newContent), newOptions, toast.TYPE.ERROR)
        return resultObj
    }

    // Actualiza el toast a tipo Warning
    resultObj.toWarning = (newContent, newOptions = {}) => {
        updateToast(
            toastId,
            getContent(toast.TYPE.WARNING, newContent),
            newOptions,
            toast.TYPE.WARNING
        )
        return resultObj
    }

    // Actualiza el toast a tipo Success
    resultObj.toSuccess = (newContent, newOptions = {}) => {
        updateToast(
            toastId,
            getContent(toast.TYPE.SUCCESS, newContent),
            newOptions,
            toast.TYPE.SUCCESS
        )
        return resultObj
    }

    return resultObj
}

export const successToast = (content, opts, customId) =>
    Toast(content, toast.TYPE.SUCCESS, opts, customId)
export const infoToast = (content, opts, customId) =>
    Toast(content, toast.TYPE.INFO, opts, customId)
export const errorToast = (content, opts, customId) =>
    Toast(content, toast.TYPE.ERROR, opts, customId)
export const warningToast = (content, opts, customId) =>
    Toast(content, toast.TYPE.WARNING, opts, customId)
