import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import screenSizesMatches from 'util/screen'
import logoMobile from 'assets/svg/AppLogo/logoMobile.svg'
import logoPasap from 'assets/svg/AppLogo/logoDesktop.svg'

import styles from './appLogo.module.scss'

const AppLogo = ({ logo }) => {
    const [isMobile, setIsMobile] = useState(screenSizesMatches('xs'))

    useEffect(() => {
        window.onresize = () => setIsMobile(screenSizesMatches('xs'))
    }, [])

    const showLogo = !logo ? logoPasap : logo
    
    return <img className={styles.logo} src={isMobile ? logoMobile : showLogo} alt="" />
}

AppLogo.defaultProps = {
    logo: logoPasap,
}

AppLogo.propTypes = {
    logo: PropTypes.string,
}

export default memo(AppLogo)
