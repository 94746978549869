import { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'
import 'util/i18n'
import Router from 'routing/Router'
import Sentry from 'util/sentry'
import { detectLang, setLang } from 'util/language'
import { RootStoreProvider } from 'providers/RootStoreProvider'
import { AuthStoreProvider } from 'providers/AuthStoreProvider'
import { PublicStoreProvider } from 'providers/PublicStoreProvider'
import AppHashValidation from 'util/appHashValidation'
import AppError from 'scenes/AppError'
import AppRefresh from 'scenes/AppRefresh'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/base.module.scss'

const lang = detectLang()

setLang(lang)

moment.locale(lang)

const FallbackRefresh = () => <AppRefresh />
const FallbackError = () => <AppError />

const App = () => {
    const [validAppHash, appHashValidate, initAppHashValidation] = AppHashValidation()

    useEffect(() => {
        initAppHashValidation()
    }, [])

    const errorHandler = useCallback(() => {
        appHashValidate()
    }, [])

    const fallback = () => (validAppHash ? <FallbackError /> : <FallbackRefresh />)



    return (
        <Sentry.ErrorBoundary onError={errorHandler} fallback={fallback}>
            <BrowserRouter>
                <PublicStoreProvider>
                    <AuthStoreProvider>
                        <RootStoreProvider>
                            <Router />
                        </RootStoreProvider>
                    </AuthStoreProvider>
                </PublicStoreProvider>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    )
}

export default observer(App)
