import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useAuthStore } from 'providers/AuthStoreProvider'
import RootStore from 'stores/RootStore'

const RootStoreContext = createContext(null)

const RootStoreProvider = ({ children }) => {
    const authStore = useAuthStore()
    const rootStore = new RootStore(authStore)

    return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
}

RootStoreProvider.propTypes = {
    children: PropTypes.element.isRequired,
}

const useRootStore = () => useContext(RootStoreContext)

// eslint-disable-next-line import/prefer-default-export
export { RootStoreProvider, useRootStore, RootStoreContext }
