const isIPhone = () => {
    return !!navigator.platform && /iPhone|iPod/.test(navigator.platform)
}

const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase()

    return ua.indexOf('android') > -1
}

const isMobile = () => {
    return isIPhone() || isAndroid()
}

export default isMobile
export { isAndroid, isIPhone }
