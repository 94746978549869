import { Suspense, useContext } from 'react'
import { observer } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import lazyLoaderComponent from 'util/lazyLoaderComponent'
import LoadingRing from 'presentation/LoadingRing'
import { CustomerSelfManagementStoreContext } from 'providers/PublicStoreProvider'
import * as Routes from 'routing/routes'
import PrivateRoute from './PrivateRoute'
import PublicRouter from './PublicRouter'
import isMobile from '../util/browser'
import MobileAppSplash from '../presentation/MobileAppSplash'

const SignIn = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "SignInComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/SignIn'
    )
)

const Register = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "RegisterComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/Register'
    )
)

const AuthorizedRouter = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "AuthorizedRouterComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'routing/AuthorizedRouter'
    )
)

const Router = () => {
    const customerSelfManagementStore = useContext(CustomerSelfManagementStoreContext)

    // ALERT: no carga la app hasta no cargar las plataformas de autogestión
    if (customerSelfManagementStore.loadingPlatforms) {
        return <LoadingRing center />
    }

    return isMobile() ? (
        <>
            <Suspense fallback={<LoadingRing center />}>
                <Switch>
                    <Route path={Routes.RESET_PASSWORD} component={Register} />
                    <Route path={Routes.CUSTOMER_SELF_MANAGEMENT} component={PublicRouter} />

                    <Route component={MobileAppSplash} />
                </Switch>
            </Suspense>
            <ToastContainer />
        </>
    ) : (
        <>
            <Suspense fallback={<LoadingRing center />}>
                <Switch>
                    <Route exact path={Routes.LOGIN} component={SignIn} />
                    <Route path={Routes.REGISTER} component={Register} />
                    <Route path={Routes.CUSTOMER_SELF_MANAGEMENT} component={PublicRouter} />

                    <PrivateRoute
                        redirectPath={Routes.LOGIN}
                        verifyPath={Routes.REGISTER_VERIFY}
                        path="/"
                        component={(props) => <AuthorizedRouter {...props} />}
                    />
                </Switch>
            </Suspense>
            <ToastContainer />
        </>
    )
}

export default observer(Router)
