import { makeAutoObservable } from 'mobx'
import validationSchemas from 'util/validationSchemas'
import {
    NOVALIDATION,
    REQUIRED,
    REQUIREDSELECT,
    EMAIL,
    PHONE,
    DNI,
    REQUIRED_DNI,
    CUIL,
    REQUIRED_CUIL,
    CBU_ALIAS_VALIDATION,
    REQUIRED_CBU_ALIAS_VALIDATION,
    CBU_VALIDATION,
    REQUIRED_CBU_VALIDATION,
    CC_VALIDATION,
    REQUIRED_CC_VALIDATION,
    BIRTH_DATE,
    REQUIRED_BIRTH_DATE,
    DATE,
    REQUIRED_DATE,
    LICENSE_PLATE,
    LICENSE_PLATE_NULLABLE,
    CUIT,
    REQUIRED_CUIT,
    EXPIRATION_DATE,
    ACCIDENT_DATE,
    REQUIRED_DNI_CUIL,
    CAPTCHA,
    CUSTOMER_SELF_PLATFORM_URL
} from 'util/validationMapping'

class Validation {
    validationSchema = null

    constructor(type, options) {
        this.type = type
        this.options = { ...options }
        this.setValidateSchema()

        makeAutoObservable(this)
    }

    async validate(store) {
        if (this.validationSchema) {
            try {
                if (this.options.validateField) {
                    await this.validationSchema.validate({
                        value: store[this.options.validateField],
                    })
                    return
                }
                await this.validationSchema.validate({ value: store.value })
            } catch (e) {
                store.setError(e.message)
            }
        }
    }

    setValidateSchema() {
        this.validationSchema = null

        if (!this.type) {
            return false
        }

        if (typeof this.type === 'object') {
            this.validationSchema = this.type
            return true
        }

        switch (this.type) {
            case NOVALIDATION:
                this.validationSchema = validationSchemas.noValidation
                break
            case REQUIRED:
                this.validationSchema = validationSchemas.requiredSchema
                break
            case REQUIREDSELECT:
                this.validationSchema = validationSchemas.requiredSelectSchema
                break
            case EMAIL:
                this.validationSchema = validationSchemas.emailSchema
                break
            case PHONE:
                this.validationSchema = validationSchemas.phoneSchema
                break
            case DNI:
                this.validationSchema = validationSchemas.dniSchema
                break
            case REQUIRED_DNI:
                this.validationSchema = validationSchemas.requiredDniSchema
                break
            case CUIL:
                this.validationSchema = validationSchemas.cuilSchema
                break    
            case REQUIRED_CUIL:
                this.validationSchema = validationSchemas.requiredCuilSchema
                break            
            case DATE:
                this.validationSchema = validationSchemas.date
                break
            case REQUIRED_DATE:
                this.validationSchema = validationSchemas.requiredDate
                break
            case BIRTH_DATE:
                this.validationSchema = validationSchemas.birthDate
                break
            case REQUIRED_BIRTH_DATE:
                this.validationSchema = validationSchemas.requiredBirthDate
                break
            case CBU_VALIDATION:
                this.validationSchema = validationSchemas.cbuSchema
                break
            case REQUIRED_CBU_VALIDATION:
                this.validationSchema = validationSchemas.requiredCbuSchema
                break
            case CBU_ALIAS_VALIDATION:
                this.validationSchema = validationSchemas.cbuAliasSchema
                break
            case REQUIRED_CBU_ALIAS_VALIDATION:
                this.validationSchema = validationSchemas.requiredCbuAliasSchema
                break
            case CC_VALIDATION:
                this.validationSchema = validationSchemas.ccSchema
                break
            case REQUIRED_CC_VALIDATION:
                this.validationSchema = validationSchemas.requiredCcSchema
                break
            case LICENSE_PLATE:
                this.validationSchema = validationSchemas.licensePlateSchema
                break
            case LICENSE_PLATE_NULLABLE:
                this.validationSchema = validationSchemas.licensePlateNullableSchema
                break
            case CUIT:
                this.validationSchema = validationSchemas.cuitSchema
                break
            case REQUIRED_CUIT:
                this.validationSchema = validationSchemas.requiredCuitSchema
                break
            case EXPIRATION_DATE:
                this.validationSchema = validationSchemas.expirationDateSchema
                break
            case ACCIDENT_DATE:
                this.validationSchema = validationSchemas.accidentDate
                break
            case REQUIRED_DNI_CUIL:
                this.validationSchema = validationSchemas.requiredCuilOrDniSchema
                break
            case CAPTCHA:
                this.validationSchema = validationSchemas.requiredCaptcha
                break
            case CUSTOMER_SELF_PLATFORM_URL:
                this.validationSchema = validationSchemas.requiredCustomerSelfManagementPlatformURL
                break
            default:
                break
        }
        return true
    }
}

export default Validation
