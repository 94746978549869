class VehicleGroup {
    constructor(id, group) {
        this.id = id
        this.group = group
    }

    static fromJson({ id, name }) {
        return new VehicleGroup(id, name)
    }
}

export default VehicleGroup
