export const DEFAULTLANG = process.env.REACT_APP_DEFAULTLANG || 'es'

export const LANGUAGES = (process.env.REACT_APP_LANGUAGES || DEFAULTLANG).split(/,\s*/)

export const detectLang = () => {
    const browserLang = navigator.languages || []
    return browserLang.find((lang) => LANGUAGES.indexOf(lang) >= 0) || DEFAULTLANG
}

export const setLang = (lang) => {
    document.documentElement.lang = lang
}
