const gridBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}

const screenSizesMatches = (size) => {
    const screen = window.innerWidth

    if (size === 'xl' && screen >= gridBreakpoints.xl) {
        return true
    }

    if (size === 'lg' && screen < gridBreakpoints.xl && screen >= gridBreakpoints.lg) {
        return true
    }

    if (size === 'md' && screen < gridBreakpoints.lg && screen >= gridBreakpoints.md) {
        return true
    }

    if (size === 'sm' && screen < gridBreakpoints.md && screen >= gridBreakpoints.sm) {
        return true
    }

    if (size === 'xs' && screen < gridBreakpoints.sm && screen >= gridBreakpoints.xs) {
        return true
    }

    return false
}

export const responsiveLimit = (limit) => {
    const screen = window.innerWidth
    return screen <= limit
}

export default screenSizesMatches
