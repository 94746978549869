import uuid from 'react-uuid'
import { observable, action, makeObservable } from 'mobx'

import InputStore from 'stores/InputStore'
import DateInputStore from 'stores/DateInputStore'
import FileInputStore from 'stores/FileInputStore'

import { ACCIDENT_DATE, EMAIL, REQUIRED } from 'util/validationMapping'
import { RISK_TYPES, STATUS } from 'util/accidentConstants'

class Accident {
    constructor() {
        this.insured = null
        this.driver = null
        this.thirdParties = null

        this.id = null
        this.status = null
        this.riskType = null
        this.insuredCompany = null
        this.plate = null
        this.policyId = null
        this.asset = null

        this.vehicleAccidentType = new InputStore(REQUIRED)
        this.replacementLocation = new InputStore()
        this.date = new DateInputStore(ACCIDENT_DATE)
        this.time = new InputStore(REQUIRED)
        this.description = new InputStore(REQUIRED)
        this.damages = new InputStore(REQUIRED)
        this.email = new InputStore(EMAIL)

        this.place = {
            city: new InputStore(REQUIRED),
            location: new InputStore(REQUIRED),
            street: new InputStore(REQUIRED),
            number: new InputStore()
        }

        this.documents = 0
        this.extensions = []
        this.extensionNote = new InputStore(REQUIRED)

        this.filesForm = {
            maxFileSize: 5242880,
            maxFiles: 10,
            supportedFormats: '*.jpg, *.png, *.pdf',
            mimeType: ['image/jpeg', 'image/png', 'application/pdf'],
            files: []
        }

        this.extensionsFilesForm = {
            maxFileSize: 5242880,
            maxFiles: 10,
            supportedFormats: '*.jpg, *.png, *.pdf',
            mimeType: ['image/jpeg', 'image/png', 'application/pdf'],
            files: []
        }

        this.addFile()
        this.addExtensionFile()

        makeObservable(this, {
            // observables
            id: observable,
            status: observable,
            riskType: observable,
            policyId: observable,
            asset: observable,
            plate: observable,
            date: observable,
            time: observable,
            place: observable,
            description: observable,
            damages: observable,
            email: observable,
            vehicleAccidentType: observable,
            replacementLocation: observable,
            insuredCompany: observable,
            documents: observable,
            extensions: observable,
            insured: observable,
            driver: observable,
            thirdParties: observable,
            extensionNote: observable,
            filesForm: observable,
            extensionsFilesForm: observable,
            // actions
            setRiskType: action,
            setIdAccident: action,
            setPolicyId: action,
            setAsset: action,
            setStatus: action,
            setPlate: action,
            setDate: action,
            setTime: action,
            setCity: action,
            setLocation: action,
            setStreet: action,
            setNumber: action,
            setDescription: action,
            setDamages: action,
            setEmail: action,
            setInsuredCompany: action,
            setDocuments: action,
            setExtensions: action,
            setExtensionNote: action,
            setExtensionImages: action,
            setExtensionPdfs: action,
            setInsured: action,
            setDriver: action,
            setThirdParties: action,
            setVehicleAccidentType: action,
            setReplacementLocation: action,
            addFile: action,
            deleteFile: action,
            addExtensionFile: action,
            deleteExtensionFile: action,
        })
    }

    addFile() {
        const { files, mimeType, maxFileSize, maxFiles, supportedFormats } = this.filesForm

        const [thisFile] = files
        thisFile?.validate()

        const fileReaderIndex = files.findIndex(({ fileSelected }) => fileSelected)

        if (files.length >= maxFiles || fileReaderIndex > 0) {
            return
        }

        this.filesForm.files.unshift(
            new FileInputStore({
                id: uuid(),
                acceptedTypeList: mimeType,
                maxFileSize,
                supportedFormats,
            })
        )
    }

    deleteFile(fileId) {
        const { filesForm: { files, maxFiles } = {} } = this
        if (files.length === 0) {
            return
        }

        this.filesForm.files = files.filter(({ id }) => id !== fileId)

        if (files.length === maxFiles) {
            this.addFile()
        }
    }

    addExtensionFile() {
        const { files, mimeType, maxFileSize, maxFiles, supportedFormats } = this.extensionsFilesForm

        const [thisFile] = files
        thisFile?.validate()

        const fileReaderIndex = files.findIndex(({ fileSelected }) => fileSelected)

        if (files.length >= maxFiles || fileReaderIndex > 0) {
            return
        }

        this.extensionsFilesForm.files.unshift(
            new FileInputStore({
                id: uuid(),
                acceptedTypeList: mimeType,
                maxFileSize,
                supportedFormats,
            })
        )
    }

    deleteExtensionFile(fileId) {
        const { extensionsFilesForm: { files, maxFiles } = {} } = this
        if (files.length === 0) {
            return
        }

        this.extensionsFilesForm.files = files.filter(({ id }) => id !== fileId)

        if (files.length === maxFiles) {
            this.addExtensionFile()
        }
    }

    setIdAccident(value) {
        this.id = value
    }

    setStatus(value) {
        this.status = value
    }

    setRiskType(value) {
        this.riskType = value
    }

    setPolicyId(value) {
        this.policyId = value
    }

    setPlate(value) {
        this.plate = value
    }

    setDate(value) {
        let useValue = value

        if (value === '') {
            useValue = null
        }

        this.date.setValue(useValue)
    }

    setTime(value) {
        this.time.setValue(value)
    }

    setCity(value) {
        this.place.city.setValue(value)
    }

    setLocation(value) {
        this.place.location.setValue(value)
    }

    setStreet(value) {
        this.place.street.setValue(value)
    }

    setNumber(value) {
        this.place.number.setValue(value)
    }

    setDamages(value) {
        this.damages.setValue(value)
    }

    setEmail(value) {
        this.email.setValue(value)
    }

    setDescription(value) {
        this.description.setValue(value)
    }

    setVehicleAccidentType(value) {
        this.vehicleAccidentType.setValue(value)
    }

    setInsuredCompany(value) {
        this.insuredCompany.setValue(value)
    }

    setDocuments(value) {
        this.documents = value
    }

    setExtensions(value) {
        this.extensions = value
    }

    setExtensionNote(value) {
        this.extensionNote.setValue(value)
    }

    setExtensionImages(value) {
        this.newExtension.documents.images = value
    }

    setExtensionPdfs(value) {
        this.newExtension.documents.pdfs = value
    }

    setInsured(value) {
        this.insured = value
    }

    setDriver(value) {
        this.driver = value
    }

    setThirdParties(value) {
        this.thirdParties = value
    }

    setReplacementLocation(value) {
        this.replacementLocation.setValue(value)
    }

    setAsset(value) {
        this.asset = value
    }

    clearErrors() {
        this.date?.clearError()
        this.time?.clearError()
        this.place?.city?.clearError()
        this.place?.location?.clearError()
        this.place?.street?.clearError()
        this.place?.number?.clearError()
        this.description?.clearError()
        this.damages?.clearError()
        this.email?.clearError()
        this.vehicleAccidentType?.clearError()
        this.extensionNote?.clearError()
    }

    json() {
        return {
            id: this.id,
            status: this.status?.id,
            riskType: this.riskType?.id,
            policyId: this.policyId,
            asset: this.asset,
            plate: this.plate,
            date: this.date?.value?.format("YYYY/MM/DD"),
            time: this.time?.value,
            place: {
                city: this.place.city?.value,
                location: this.place.location?.value,
                street: this.place.street?.value,
                number: this.place.number?.value ? this.place.number.value : 0
            },
            description: this.description?.value,
            damages: this.damages?.value,
            email: this.email?.value,
            vehicleAccidentType: this.vehicleAccidentType?.value.id,
            replacementLocation: this.replacementLocation?.value,
            insuredCompany: this.insuredCompany?.id,
            insured: this.insured?.json(),
            driver: this.driver?.json(),
            thirdParties: this.thirdParties?.json(),
        }
    }

    async validate() {
        this.clearErrors()
        await this.date?.validate()
        await this.time?.validate()
        await this.place?.city?.validate()
        await this.place?.location?.validate()
        await this.place?.street?.validate()
        await this.place?.number?.validate()
        await this.description?.validate()
        await this.damages?.validate()
        await this.email?.validate()
        await this.vehicleAccidentType?.validate()
        await this.extensionNote.validate()
    }

    get isVehicle() {
        return (
            this.riskType === RISK_TYPES.CAR ||
            this.riskType === RISK_TYPES.MOTO
        )
    }

    get isSent() {
        return (
            this.status?.id === STATUS.SENT ||
            this.status?.id === STATUS.PENDING_INSPECTION ||
            this.status?.id === STATUS.PENDING_ORDER ||
            this.status?.id === STATUS.IN_REPAIR ||
            this.status?.id === STATUS.NEW_EXTENSION ||
            this.status?.id === STATUS.PAYED ||
            this.status?.id === STATUS.WITHOUT_RESPONSIBILITY ||
            this.status?.id === STATUS.WITHOUT_ANSWER ||
            this.status?.id === STATUS.REJECTED ||
            this.status?.id === STATUS.DISCARDED
        )
    }

    get isClosed() {
        return (
            this.status?.id === STATUS.PAYED ||
            this.status?.id === STATUS.WITHOUT_RESPONSIBILITY ||
            this.status?.id === STATUS.WITHOUT_ANSWER ||
            this.status?.id === STATUS.REJECTED ||
            this.status?.id === STATUS.DISCARDED
        )
    }

    fromAPI(data) {
        // console.log('AccidentFromAPI: ', data)
        this.setIdAccident(data?.id)
        this.setStatus(data?.status)
        this.setAsset(data?.asset)
        this.setRiskType(data?.riskType)
        this.insuredCompany = data?.insuredCompany
        this.policyId = data?.policyId
        this.setPlate(data?.plate)
        this.setDate(data?.date)
        this.setTime(data?.time)
        this.setCity(data?.place?.city)
        this.setLocation(data?.place?.location)
        this.setStreet(data?.place?.street)
        this.setNumber(data?.place?.number)
        this.setDescription(data?.description)
        this.setDamages(data?.damages)
        this.setEmail(data?.email)
        this.setVehicleAccidentType(data?.vehicleAccidentType)
        this.setReplacementLocation(data?.replacementLocation)
        this.setDocuments(data?.documents)
        this.setInsured(data?.insured)
        this.setDriver(data?.driver)
        this.setThirdParties(data?.thirdParties)

        if (data?.extensions?.length > 0) {
            this.setExtensions(data?.extensions)
        }

        setTimeout(() => this.clearErrors(), 1)
        // console.log('AccidentPostAPI: ', this)
    }
}

export default Accident
