import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import policy from 'assets/svg/DocumentIcon/policy.svg'
import circulation from 'assets/svg/DocumentIcon/circulation.svg'
import mercosur from 'assets/svg/DocumentIcon/mercosur.svg'
import provisional from 'assets/svg/DocumentIcon/provisional.svg'
import genericDocument from 'assets/svg/DocumentIcon/generic.svg'
import endoso from 'assets/svg/DocumentIcon/endoso.svg'

import facebook from 'assets/svg/SocialNetworkIcon/facebook.svg'
import instagram from 'assets/svg/SocialNetworkIcon/instagram.svg'
import youtube from 'assets/svg/SocialNetworkIcon/youtube.svg'
import linkedin from 'assets/svg/SocialNetworkIcon/linkedin.svg'
import genericLink from 'assets/svg/SocialNetworkIcon/generic.svg'

import pasapMini from 'assets/svg/PublicAppLogo/logoPasapMini.svg'

import { CUSTOMER_SELF_MANAGEMENT_DOCUMENTS, CUSTOMER_SELF_MANAGEMENT_SOCIAL_NETWORKS } from 'stores/CustomerSelfManagementStore'

import styles from './svg.module.scss'

const SVG = ({ tag, type }) => {
    let icon = null;

    if (type === "document") {
        switch (tag) {
            case CUSTOMER_SELF_MANAGEMENT_DOCUMENTS.POLICY:
                icon = policy
                break;
            case CUSTOMER_SELF_MANAGEMENT_DOCUMENTS.CIRCULATION:
                icon = circulation
                break;
            case CUSTOMER_SELF_MANAGEMENT_DOCUMENTS.MERCOSUR:
                icon = mercosur
                break;
            case CUSTOMER_SELF_MANAGEMENT_DOCUMENTS.PROVISIONAL:
                icon = provisional
                break;
            case CUSTOMER_SELF_MANAGEMENT_DOCUMENTS.AMENDMENT:
                icon = endoso
                break;
            default:
                icon = genericDocument
                break;
        }
    }

    if (type === "link") {
        switch (tag) {
            case CUSTOMER_SELF_MANAGEMENT_SOCIAL_NETWORKS.FACEBOOK:
                icon = facebook
                break;
            case CUSTOMER_SELF_MANAGEMENT_SOCIAL_NETWORKS.YOUTUBE:
                icon = youtube
                break;
            case CUSTOMER_SELF_MANAGEMENT_SOCIAL_NETWORKS.INSTAGRAM:
                icon = instagram
                break;
            case CUSTOMER_SELF_MANAGEMENT_SOCIAL_NETWORKS.LINKEDIN:
                icon = linkedin
                break;
            default:
                icon = genericLink
                break;
        }
    }

    if (type === "logo") {
        icon = pasapMini
    }

    return (
        <img className={styles.[type]} src={icon} alt={tag} />
    )
}

SVG.propTypes = {
    tag: PropTypes.string,
    type: PropTypes.string.isRequired,
}

SVG.defaultProps = {
    tag: null
}


export default observer(SVG)
