import CommissionsPermissions from 'models/Permissions/AbstractPermissions/CommissionsPermissions'
import OrganizerPermissions from '../AbstractPermissions/OrganizerPermissions'
import BrokerPermissions from '../AbstractPermissions/BrokerPermissions'
import CarouselPermissions from '../AbstractPermissions/CarouselPermissions'
import ManualPermissions from '../AbstractPermissions/ManualPermissions'
import SelfManagementPlatformPermissions from '../AbstractPermissions/SelfManagementPlatformPermissions'

class BaseScenesPermissions {
    constructor({ 
        enabled = false, 
        show = true, 
        commissions = {}, 
        organizer = {}, 
        brokerParent = {}, 
        carousel = {}, 
        manual = {},
        selfManagementPlatformLogo = {},
    } = {}) {
        this.enabled = enabled
        this.show = show
        this.commissions = new CommissionsPermissions(commissions)
        this.organizer = new OrganizerPermissions(organizer)
        this.brokerParent = new BrokerPermissions(brokerParent)
        this.carousel = new CarouselPermissions(carousel)
        this.manual = new ManualPermissions(manual)
        this.selfManagementPlatformLogo = new SelfManagementPlatformPermissions(selfManagementPlatformLogo)
    }
}

export default BaseScenesPermissions
