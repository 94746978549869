import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import AccidentStore from 'stores/AccidentStore';
import CustomerSelfManagementStore from 'stores/CustomerSelfManagementStore';

export const AccidentStoreContext = createContext(null);
export const CustomerSelfManagementStoreContext = createContext(null);

export const PublicStoreProvider = ({ children }) => {
    const accidentStore = new AccidentStore();
    const customerSelfManagementStore = new CustomerSelfManagementStore();

    useEffect(() => {
        const fetchPlatforms = async () => {
            await customerSelfManagementStore.listPlatforms();
        };

        fetchPlatforms();
    }, [customerSelfManagementStore]);

    return (
        <AccidentStoreContext.Provider value={accidentStore}>
            <CustomerSelfManagementStoreContext.Provider value={customerSelfManagementStore}>
                {children}
            </CustomerSelfManagementStoreContext.Provider>
        </AccidentStoreContext.Provider>
    );
};

PublicStoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
