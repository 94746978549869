import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import moment from 'moment'
import preval from 'preval.macro'
import { APP_RELEASE, APP_ENVIROMENT } from 'util/constants'

const cmplTs = preval`module.exports = Date.now()`

const releaseDefault = APP_RELEASE || moment(cmplTs).format('YYYYMMDDHHmmss')
const environmentDefault = APP_ENVIROMENT || 'unknown'
const tracesSampleRateDefault = process.env.REACT_APP_SENTRY_TRACESSAMPLERATE || 0.7

const SentryInit = ({
    dsn = process.env.REACT_APP_SENTRY_DSN,
    beforeSend,
    tracesSampleRate = tracesSampleRateDefault,
    release = releaseDefault,
    environment = environmentDefault,
    integrations = [new Integrations.BrowserTracing()],
} = {}) => Sentry.init({ dsn, release, environment, tracesSampleRate, beforeSend, integrations })

const setUser = (
    { name: firstName, lastName, email, activated, onboardingStatus, phoneNumber } = {
        name: 'unknown',
    }
) => {
    const name = firstName && lastName ? `${firstName} ${lastName}` : firstName

    Sentry.setUser({
        name,
        email,
        activated,
        onboardingStatus,
        phoneNumber,
    })
}

const clearUser = () => Sentry.configureScope((scope) => scope.setUser(null))

export default Sentry

export { SentryInit, Integrations, setUser, clearUser }
