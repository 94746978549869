import { makeAutoObservable } from 'mobx'

import {
    ON_BOARDING_FINISHED,
    ON_BOARDING_LEVEL_ONE_PENDING,
    ON_BOARDING_LEVEL_ONE_REVIEW,
    ON_BOARDING_LEVEL_TWO_PENDING,
    ON_BOARDING_LEVEL_TWO_REVIEW,
} from 'util/constants'

class Broker {
    constructor(
        id,
        name,
        lastName,
        email,
        phoneNumber,
        professionalLicense,
        professionalLicenseBackImage,
        professionalLicenseFrontImage,
        streetName,
        streetNumber,
        floor,
        flat,
        locality,
        bankAccount,
        taxIdentification,
        grossIncomeTax,
        grossIncomeTaxImage,
        multilateralAgreement,
        professionalLicensePaymentImage,
        afipInscriptionImage,
        vatCondition,
        uifImage,
        onBoardingLevel,
        onBoardingProcess,
        identityType,
        identityNumber,
        birthday,
        nationality,
        identityFrontImage,
        identityBackImage,
        token,
        activated,
        onBoardingStatus,
        permissions,
        displayName,
        displayNameSelect,
        logo,
        selfManagementURL,
    ) {
        this.id = id
        this.name = name
        this.lastName = lastName
        this.email = email
        this.phoneNumber = phoneNumber
        this.professionalLicense = professionalLicense
        this.professionalLicenseBackImage = professionalLicenseBackImage
        this.professionalLicenseFrontImage = professionalLicenseFrontImage
        this.streetName = streetName
        this.streetNumber = streetNumber
        this.floor = floor
        this.flat = flat
        this.locality = locality
        this.bankAccount = bankAccount
        this.taxIdentification = taxIdentification
        this.grossIncomeTax = grossIncomeTax
        this.grossIncomeTaxImage = grossIncomeTaxImage
        this.multilateralAgreement = multilateralAgreement
        this.professionalLicensePaymentImage = professionalLicensePaymentImage
        this.afipInscriptionImage = afipInscriptionImage
        this.vatCondition = vatCondition
        this.uifImage = uifImage
        this.onBoardingLevel = onBoardingLevel
        this.onBoardingProcess = onBoardingProcess
        this.identityType = identityType
        this.identityNumber = identityNumber
        this.birthday = birthday
        this.nationality = nationality
        this.identityFrontImage = identityFrontImage
        this.identityBackImage = identityBackImage
        this.token = token
        this.activated = activated
        this.onboardingStatus = onBoardingStatus
        this.permissions = permissions
        this.displayName = displayName
        this.displayNameSelect = displayNameSelect
        this.logo = logo
        this.selfManagementURL = selfManagementURL

        makeAutoObservable(this)
    }

    get isLevelZero() {
        return (
            this.onboardingStatus === ON_BOARDING_LEVEL_ONE_PENDING ||
            this.onboardingStatus === ON_BOARDING_LEVEL_ONE_REVIEW
        )
    }

    get isLevelOne() {
        return (
            this.onboardingStatus === ON_BOARDING_LEVEL_TWO_PENDING ||
            this.onboardingStatus === ON_BOARDING_LEVEL_TWO_REVIEW
        )
    }

    get isLevelTwo() {
        return this.onboardingStatus === ON_BOARDING_FINISHED
    }

    get onBoardingReviewing() {
        return (
            this.onboardingStatus === ON_BOARDING_LEVEL_ONE_REVIEW ||
            this.onboardingStatus === ON_BOARDING_LEVEL_TWO_REVIEW
        )
    }

    get isActivated() {
        return this.activated === true
    }

    activate() {
        this.activated = true
    }

    setOnBoardingLevel(onBoardingLevel) {
        this.onBoardingLevel = onBoardingLevel
    }

    setOnBoardingProcess(onBoardingProcess) {
        this.onBoardingProcess = onBoardingProcess
    }

    static fromJson({
        id,
        name,
        lastName,
        email,
        phoneNumber,
        professionalLicense,
        professionalLicenseBackImage,
        professionalLicenseFrontImage,
        streetName,
        streetNumber,
        floor,
        flat,
        locality,
        bankAccount,
        taxIdentification,
        grossIncomeTax,
        grossIncomeTaxImage,
        multilateralAgreement,
        professionalLicensePaymentImage,
        afipInscriptionImage,
        vatCondition,
        uifImage,
        onBoardingLevel,
        onBoardingProcess,
        identityType,
        identityNumber,
        birthday,
        nationality,
        identityFrontImage,
        identityBackImage,
        access_token: token,
        activated,
        onBoardingStatus,
        permissions,
        displayName,
        displayNameSelect,
        logo,
        selfManagementURL
    }) {
        return new Broker(
            id,
            name,
            lastName,
            email,
            phoneNumber,
            professionalLicense,
            professionalLicenseBackImage,
            professionalLicenseFrontImage,
            streetName,
            streetNumber,
            floor,
            flat,
            locality,
            bankAccount,
            taxIdentification,
            grossIncomeTax,
            grossIncomeTaxImage,
            multilateralAgreement,
            professionalLicensePaymentImage,
            afipInscriptionImage,
            vatCondition,
            uifImage,
            onBoardingLevel,
            onBoardingProcess,
            identityType,
            identityNumber,
            birthday,
            nationality,
            identityFrontImage,
            identityBackImage,
            token,
            activated,
            onBoardingStatus,
            permissions,
            displayName,
            displayNameSelect,
            logo,
            selfManagementURL
        )
    }
}

export default Broker
