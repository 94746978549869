class ShowDataItem {
    constructor(key, value, multiline = false) {
        this.key = key
        this.value = value
        this.multiline = multiline
    }

    static fromJson({ key, value }) {
        return new ShowDataItem(key, value)
    }

    fromStorage({ id, value, multiline }) {
        this.id = id
        this.value = value
        this.multiline = multiline
    }
}

export default ShowDataItem
