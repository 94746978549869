import { memo } from 'react'
import Sentry from 'util/sentry'
import { useTranslation } from 'react-i18next'
import styles from './appError.module.scss'

const AppError = () => {
    const { t } = useTranslation('appError')

    const reportDialogConfig = {
        title: t('reportDialog.title'),
        subtitle: t('reportDialog.subtitle'),
        subtitle2: t('reportDialog.subtitle2'),
        labelComments: t('reportDialog.labelComments'),
        labelSubmit: t('reportDialog.labelSubmit'),
        onLoad() {
            const comments = document.getElementById('id_comments')
            if (comments) {
                comments.setAttribute('placeholder', t('reportDialog.commentsPlaceholder'))
            }
        },
    }

    return (
        <div className={styles.container}>
            <div className={styles.error}>
                <h1 className={styles.title}>{t('title')}</h1>
                <div className={styles.message}>
                    {t('message')}
                    <br />
                    {t('message2')}
                    <br />
                    {t('message3')}
                </div>
                <div>
                    <button
                        type="button"
                        onClick={() => {
                            window.location.replace('/')
                            window.location.reload()
                        }}
                        className={styles.button}
                    >
                        {t('buttonLabel')}
                    </button>
                    <div
                        tabIndex="0"
                        role="button"
                        onClick={() => Sentry.showReportDialog(reportDialogConfig)}
                        className={styles.reportLink}
                    >
                        {t('linkLabel')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(AppError)
