import * as yup from 'yup'
import i18next from 'i18next'
import moment from 'moment'
import CommonDataStore from 'stores/CommonDataStore'
import { isValidCuit, isValidCBU, isValidCC } from 'util/validation'

const noValidation = yup.object().shape({
    value: yup.string().nullable().notRequired(),
})

const emailSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .email(() => i18next.t('field_email')),
})

const requiredSchema = yup.object().shape({
    value: yup
        .string()
        .nullable()
        .required(() => i18next.t('field_required')),
})

const requiredSelectSchema = yup.object().shape({
    value: yup
        .object()
        .nullable()
        .required(() => i18next.t('field_required'))
        .shape({
            value: yup
                .string()
                .nullable()
                .required(() => i18next.t('field_required')),
        }),
})

// buscar validacion de telefono
const phoneSchema = yup.object().shape({
    value: yup.string().required(() => i18next.t('field_required')),
})

const birthDate = yup.object().shape({
    value: yup
        .date(() => i18next.t('field_date_invalid'))
        .nullable()
        .max(moment().subtract(CommonDataStore.getMinAge(), 'years'), (data) =>
            i18next.t('field_date_max', {
                date: moment(data.max).format(i18next.t('datePickerFormat')),
            })
        )
        .typeError(() => i18next.t('field_date_invalid')),
})

const requiredBirthDate = yup.object().shape({
    value: yup
        .date()
        .required(() => i18next.t('field_required'))
        .max(moment().subtract(CommonDataStore.getMinAge(), 'years'), (data) =>
            i18next.t('field_date_max', {
                date: moment(data.max).format(i18next.t('datePickerFormat')),
            })
        )
        .typeError((data) => {
            if (data.originalValue instanceof moment) {
                return i18next.t('field_date_invalid')
            }

            return i18next.t('field_required')
        }),
})

const date = yup.object().shape({
    value: yup.date().typeError((data) => {
        if (data.originalValue instanceof moment) {
            return i18next.t('field_date_invalid')
        }

        return null
    }),
})

const requiredDate = yup.object().shape({
    value: yup
        .date()
        .required(() => i18next.t('field_required'))
        .typeError((data) => {
            if (data.originalValue instanceof moment) {
                return i18next.t('field_date_invalid')
            }

            return i18next.t('field_required')
        }),
})

const dniSchema = yup.object().shape({
    value: yup
        .string()
        .nullable()
        .when('$strict', {
            is: true,
            then: yup.string().required(i18next.t('required_field')),
            otherwise: yup.string().notRequired(),
        })
        .test(
            'is-valid-dni',
            i18next.t('invalid_dni'),
            (value) => value === null || value === '' || /^[1-9]\d{6,7}$/.test(value),
        ),
});

const requiredDniSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .matches(/^[1-9]\d{6,7}$/, i18next.t('invalid_dni')),
})

const cuilSchema = yup.object().shape({
    value: yup
        .string()
        .nullable()
        .when('$strict', {
            is: true,
            then: yup.string().required(i18next.t('required_field')),
            otherwise: yup.string().notRequired(),
        })
        .test(
            'is-valid-cuil',
            i18next.t('invalid_cuil'),
            (value) => value === null || value === '' || /^(20|23|27|30|33)([1-9]\d{6,7})([0-9]{1})$/g.test(value),
        ),
});

const requiredCuilSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .matches(/^(20|23|27|30|33)([1-9]\d{6,7})([0-9]{1})$/g, i18next.t('invalid_cuil')),
})

const cbuSchema = yup.object().shape({
    value: yup.string().notRequired().test('cbu_valid', i18next.t('invalid_cbu'), isValidCBU),
})

const requiredCbuSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .test('cbu_valid', i18next.t('invalid_cbu'), isValidCBU),
})

const cbuAliasSchema = yup.object().shape({
    value: yup
        .string()
        .notRequired()
        .matches(/^(.{0}|\d{22}|[\w\-.]{6,20})$/, i18next.t('invalid_cbu_alias')),
})

const requiredCbuAliasSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .matches(/^(\d{22}|[\w\-.]{6,20})$/, i18next.t('invalid_cbu_alias')),
})

const ccSchema = yup.object().shape({
    value: yup.string().notRequired().test('cc_valid', i18next.t('invalid_cc'), isValidCC),
})

const requiredCcSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .test('cc_valid', i18next.t('invalid_cc'), isValidCC),
})

const licensePlateSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .matches(
            /^(([a-z]{3,3}\d{3,3})|([a-z]{2,2}\d{3,3}[a-z]{2,2})|((cd|cc|oi|me|pa)\d{4,4})|((d|c|i|m|a)\d{3,3}[a-z]{3,3}))$/gi,
            i18next.t('invalidLicensePlate')
        ),
})

const licensePlateNullableSchema = yup.object().shape({
    value: yup
        .string()
        .nullable()
        .when('$strict', {
            is: true,
            then: yup.string().required(i18next.t('required_field')),
            otherwise: yup.string().notRequired(),
        })
        .test(
            'is-valid-licensePlate',
            i18next.t('invalidLicensePlate'),
            (value) => value === null || value === '' || /^(([a-z]{3,3}\d{3,3})|([a-z]{2,2}\d{3,3}[a-z]{2,2})|((cd|cc|oi|me|pa)\d{4,4})|((d|c|i|m|a)\d{3,3}[a-z]{3,3})|(\d{3}[a-z]{3})|(\d{3}[a-z]{2}\d{3}[a-z]{2})|(\d{3}[a-z]{3}\d{3}))$/gi.test(value),
        ),
});
  
const cuitSchema = yup.object().shape({
    value: yup.string().notRequired().test('cuit_valid', i18next.t('invalid_cuit'), isValidCuit),
})

const requiredCuitSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .test('cuit_valid', i18next.t('invalid_cuit'), isValidCuit),
})

const expirationDateSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .test('expirationDate', i18next.t('field_expirationDate'), (value) => {
            if (value && moment(value, 'MM/YY').isValid()) {
                return true
            }
            return false
        }),
})

const accidentDate = yup.object().shape({
    value: yup
        .date()
        .required(() => i18next.t('field_required'))
        .max(new Date(new Date().setHours(23, 59, 59, 999)), () =>
            i18next.t('field_date_max', {
                date: moment().format(i18next.t('datePickerFormat')),
            })
        )
        .typeError(() => i18next.t('field_date_invalid')),
});

const requiredCuilOrDniSchema = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .matches(
            /^(20|23|27|30|33)([1-9]\d{6,7})([0-9]{1})$|^[1-9]\d{6,7}$/,
            i18next.t('invalid_cuil_or_dni')
        ),
});

const requiredCaptcha = yup.object().shape({
    value: yup
        .string()
        .required(() => i18next.t('field_required'))
        .matches(
            /^\d{6}$/,
            { message: i18next.t('invalid_captcha'), excludeEmptyString: true }
        ),
});

const requiredCustomerSelfManagementPlatformURL = yup.object().shape({
    value: yup
        .string()
        .matches(
            /^(?!-)(?!.*--)(?!.*-$)[A-Za-z-]+$/,
            i18next.t('invalid_url')
        ),
});

export default {
    noValidation,
    requiredSchema,
    requiredSelectSchema,
    emailSchema,
    phoneSchema,
    dniSchema,
    requiredDniSchema,
    cuilSchema,
    requiredCuilSchema,
    cbuSchema,
    requiredCbuSchema,
    cbuAliasSchema,
    requiredCbuAliasSchema,
    ccSchema,
    requiredCcSchema,
    birthDate,
    requiredBirthDate,
    date,
    requiredDate,
    licensePlateSchema,
    licensePlateNullableSchema,
    cuitSchema,
    requiredCuitSchema,
    expirationDateSchema,
    accidentDate,
    requiredCuilOrDniSchema,
    requiredCaptcha,
    requiredCustomerSelfManagementPlatformURL
}
