import { observer } from 'mobx-react';
import React, { Suspense, useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import PublicHeader from 'scenes/PublicHeader';
import PublicFooter from 'scenes/PublicFooter';
import LoadingRing from 'presentation/LoadingRing';
import PublicMainLayout from 'presentation/PublicMainLayout';
import lazyLoaderComponent from 'util/lazyLoaderComponent';
import { CustomerSelfManagementStoreContext } from 'providers/PublicStoreProvider';

import * as Routes from 'routing/routes';

const CustomerSelfManagement = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "CustomerSelfManagementComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/CustomerSelfManagement'
    )
);

const Customer = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "CustomerComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/CustomerSelfManagement/Customer'
    )
);

const CustomerDocuments = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "CustomerDocumentsComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/CustomerSelfManagement/CustomerDocuments'
    )
);

const Accidents = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "AccidentsComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/Accidents'
    )
);

const AccidentDetails = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "AccidentDetailsComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/Accidents/AccidentDetail'
    )
);

const CustomerPaymentMethod = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "CustomerPaymentMethodComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/CustomerSelfManagement/CustomerPaymentMethod'
    )
);

const CustomerPolicyData = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "CustomerPolicyDataComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/CustomerSelfManagement/CustomerPolicyData'
    )
);

const CustomerInspections = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "CustomerInspectionsComponent" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/CustomerSelfManagement/CustomerInspections'
    )
);

const NotFound404 = lazyLoaderComponent(() =>
    import(
        /* webpackChunkName: "NotFound404Component" */
        /* webpackMode: "lazy" */
        /* webpackExports: ["default"] */
        'scenes/NotFound404'
    )
);

const PublicRouter = () => {
    const customerSelfManagementStore = useContext(CustomerSelfManagementStoreContext);

    useEffect(() => {
        const currentPath = window.location.pathname;
        customerSelfManagementStore.setPlatformByPath(currentPath);
    }, [customerSelfManagementStore]);

    return (
        <PublicMainLayout header={PublicHeader} footer={PublicFooter}>
            <Suspense fallback={<LoadingRing center />}>
                <Switch>
                    {customerSelfManagementStore.platforms?.flatMap((platform) => [
                        <Route key={`${platform.name}-main`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}`} component={CustomerSelfManagement} />,
                        <Route key={`${platform.name}-clients`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_CLIENTS}`} component={Customer} />,
                        <Route key={`${platform.name}-documents`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_DOCUMENTS}`} component={CustomerDocuments} />,
                        <Route key={`${platform.name}-accidents`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_ACCIDENTS}`} component={Accidents} />,
                        <Route key={`${platform.name}-accident-details`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_ACCIDENTS_DETAIL}`} component={AccidentDetails} />,
                        <Route key={`${platform.name}-payment-method`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_PAYMENT_METHOD}`} component={CustomerPaymentMethod} />,
                        <Route key={`${platform.name}-policy-data`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_POLICY_DATA}`} component={CustomerPolicyData} />,
                        <Route key={`${platform.name}-inspections`} exact path={`${Routes.CUSTOMER_SELF_MANAGEMENT}/${platform.name}${Routes.CUSTOMER_SELF_MANAGEMENT_INSPECTIONS}`} component={CustomerInspections} />
                    ])}
                    <Route component={NotFound404} />
                </Switch>
            </Suspense>
        </PublicMainLayout>
    );
};

export default observer(PublicRouter);
