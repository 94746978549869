import { useContext } from 'react'
import { observer } from 'mobx-react'

import SVG from 'presentation/SVG'
import { CustomerSelfManagementStoreContext } from 'providers/PublicStoreProvider'

import styles from './publicFooter.module.scss'

const PublicFooter = () => {
    const customerSelfManagementStore = useContext(CustomerSelfManagementStoreContext)

    const { links, platform } = customerSelfManagementStore
    const pasapLanding = "https://pasap.com.ar/"

    return (
        <div className={styles.policyFooterActions}>
            <a href={pasapLanding} target="_blank" rel="noopener noreferrer">
                <SVG tag={platform} type="logo" />
            </a>
            
            <div className={styles.policyFooterButtons}>
                { links.map((link) => {
                    return (
                        <a href={link?.value} target="_blank" rel="noopener noreferrer" key={link?.id}>
                            <SVG tag={link?.id} type="link" />
                        </a>
                    )
                })} 
            </div>
        </div>
    )
}

export default observer(PublicFooter)
