import { observable, action, makeObservable } from 'mobx'
import ScenesPermissions from './ScenesPermissions'

class PermissionsStore {
    constructor(permissions = {}) {
        this.permissions = new ScenesPermissions(permissions?.scenes)
        this.makeObservables()
    }

    update(permissions) {
        this.permissions = new ScenesPermissions(permissions?.scenes)
    }

    scene(scene) {
        return this.permissions.child(scene)
    }

    makeObservables() {
        makeObservable(this, {
            permissions: observable,
            update: action,
        })
    }
}

export default PermissionsStore
