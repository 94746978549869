class CarCoverageSummary {
    constructor(infoAutoCode) {
        this.infoAutoCode = infoAutoCode
    }

    static fromJson({ infoAutoCode, price }) {
        return new CarCoverageSummary(infoAutoCode, price)
    }
}

export default CarCoverageSummary
