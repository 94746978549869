class ScenesPermissions {
    constructor(scenes) {
        this.scenes = scenes
    }

    child(scene) {
        return new ScenesPermissions(this.scenes?.[scene])
    }

    permission(action) {
        return this.scenes?.[action]
    }
}

export default ScenesPermissions
