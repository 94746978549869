/* eslint-disable no-param-reassign */
import axios from 'axios'

const axiosInterceptors = (authStore) => {
    // Handles all 503 requests
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 503) {
                authStore.setApiOffline()
            }

            return Promise.reject(error)
        }
    )

    // Handles all 403 requests
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response !== undefined && error.response?.status > 403) {
                error.response.unknown = true
            }

            return Promise.reject(error)
        }
    )

    // Handles all 401 requests
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response?.status === 401 && authStore.isAuthenticated) {
                authStore.logout()
            }

            return Promise.reject(error)
        }
    )

    // Adds bearer to all API requests
    axios.interceptors.request.use(
        (config) => {
            if (authStore.isAuthenticated) {
                config.headers.Authorization = `Bearer ${authStore.accessToken}`
            }

            // Do something before request is sent
            return config
        },
        (error) => Promise.reject(error)
    )

    // Token update
    axios.interceptors.response.use(
        (response) => {
            if (
                response &&
                response.data &&
                response.data?.metadata &&
                response.data?.metadata?.token
            ) {
                // updates jwtoken if needed
                authStore.updateToken(response.data?.metadata?.token?.token)
            }

            return response
        },
        (error) => Promise.reject(error)
    )

    // Add referer on GET request
    // TODO: implement thins instead of change url on services on the future
    // axios.interceptors.request.use(
    //     (request) => {
    //         const { method, url } = request

    //         if (method === 'get') {
    //             const currentUrl = new URL(url)
    //             currentUrl.searchParams.append('referer', 'web')

    //             return { ...request, url: currentUrl.href }
    //         }

    //         return request
    //     },
    //     (error) => Promise.reject(error)
    // )
}

export default axiosInterceptors
