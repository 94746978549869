import { observable, action, makeObservable, runInAction } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import NeedHelpService from 'services/NeedHelpService/NeedHelpService'

class NeedHelpStore extends AsyncStore {
    constructor() {
        super()

        this.help = null
        this.isLoading = true

        makeObservable(this, {
            help: observable,
            loadHelpData: action,
        })

        this.helpService = new NeedHelpService()
    }

    async loadHelpData() {
        if (this.help === null) {
            this.preRequest()

            try {
                const help = await this.helpService.getHelpData()

                runInAction(() => {
                    this.help = help

                    this.onSuccessRequest()
                })
            } catch (e) {
                this.onErrorRequest(e)
            }
        }
    }
}

export default NeedHelpStore
