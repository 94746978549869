class SelectOption {
    constructor(id, value, extra) {
        this.id = id
        this.value = value
        this.extra = extra
    }

    static fromJson({ id, name, extra = null }) {
        return new SelectOption(id, name, extra)
    }
}

export default SelectOption
