import axios from 'axios'
import { API_URL } from 'services/config'
import BrokerDto from 'dto/BrokerDto'

const { CancelToken } = axios
const ACCESS_TOKEN = 'access_token'

class AuthService {
    static persistAccessToken(token) {
        if (token) {
            localStorage.setItem(ACCESS_TOKEN, token)
        }
    }

    static getAccessToken() {
        return localStorage.getItem(ACCESS_TOKEN)
    }

    static removeAccessToken() {
        localStorage.removeItem(ACCESS_TOKEN)
    }

    static async fetchAuthUserData(token) {
        const req = axios.create({
            headers: { Authorization: `Bearer ${token || AuthService.getAccessToken()}` },
        })
        const responseData = await req
            .get(`${API_URL}/broker-data`)
            .then(({ data: { data } }) => data)

        return BrokerDto.fromJson(responseData)
    }

    static async authenticate(email, password) {
        const response = await axios
            .post(`${API_URL}/authentication/authorize`, {
                email,
                password,
            })
            .then(({ data: { data } }) => data)

        AuthService.persistAccessToken(response.access_token)

        return {
            authUser: BrokerDto.fromJson(response),
            token: response.access_token,
        }
    }

    static async refreshToken() {
        if (CancelToken.source().cancel) {
            CancelToken.source().cancel('Only one request allowed at a time.')
        }

        const cancelToken = CancelToken.source().token

        const response = await axios.post(`${API_URL}/authentication/refresh`, {}, { cancelToken })
        const { access_token: accessToken } = response.data.data

        AuthService.persistAccessToken(accessToken)

        return accessToken
    }

    static async logout() {
        try {
            await axios.post(`${API_URL}/authentication/web/logout`)
            AuthService.removeAccessToken()
            return true
        } catch (e) {
            AuthService.removeAccessToken()
            return false
        }
    }

    static async authorizeWithToken(token) {
        const instance = axios.create({
            baseURL: 'https://some-domain.com/api/',
            timeout: 1000,
            headers: { Authorization: `Bearer ${token}` },
        })

        instance.get()
    }
}

export default AuthService
