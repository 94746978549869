import BaseScenesPermissions from 'models/Permissions/BaseScenesPermissions'

class PolicyManualPermissions extends BaseScenesPermissions {
    constructor({
        show = true,
        enabled = true,
        create = false,
        update = false,
        remove = false,
    } = {}) {
        super({ show, enabled })
        this.create = create
        this.update = update
        this.remove = remove
    }
}

export default PolicyManualPermissions
