import axios from 'axios'
import i18next from 'i18next'
import { API_URL } from 'services/config'
import VehicleBrand from 'models/VehicleBrand'
import VehicleGroup from 'models/VehicleGroup'
import VehicleVersion from 'models/VehicleVersion'
import CarCoverageSummary from 'models/CarCoverageSummary'
import VehicleCluster from 'models/VehicleCluster'
import ClusterCustomer from 'models/ClusterCustomer'
import AdvancedConfigOption from 'models/AdvancedConfigOption'
import SelectOption from 'models/SelectOption'
import Province from 'models/Province'
import RequiringCoveragesService from './RequiringCoveragesService'

export const QUOTE_CAR_FORM_NAME = 'carQuoteData'
export const CBU = 'bankAccount'
export const CARD = 'creditCard'

class QuoteCreationService {
    companiesJson = [
        { name: 'Zurich', id: 'Zurich' },
        { name: 'Allianz', id: 'Allianz' },
        { name: 'Sura', id: 'Sura' },
    ]

    genderJson = [
        { name: i18next.t('quoteCreation:female'), id: 'female' },
        { name: i18next.t('quoteCreation:male'), id: 'male' },
    ]

    getYears = () =>
        axios.get(`${API_URL}/multi-quoter/car/years`).then((response) => response.data)

    getBrands = (type, year) =>
        axios
            .get(`${API_URL}/multi-quoter/${type}/brands?year=${year}`)
            .then((response) => response.data.data.map((brand) => VehicleBrand.fromJson(brand)))

    getGroups = (type, year, brand) =>
        axios
            .get(`${API_URL}/multi-quoter/${type}/brands/${brand.id}/groups?year=${year}`)
            .then((response) => response.data.data.map((group) => VehicleGroup.fromJson(group)))

    getVersions = (type, year, brand) =>
        axios
            .get(`${API_URL}/multi-quoter/${type}/models?year=${year}&brand=${brand?.id}`)
            .then(({ data: { data } }) =>
                data.map((carVersion) => VehicleVersion.fromJson(carVersion))
            )

    getMoreVersions = (type, year, brand, group) =>
        axios
            .post(`${API_URL}/multi-quoter/${type}/models/search-more`, {
                year,
                group: Number(group?.id),
                brand: Number(brand?.id),
            })
            .then(({ data: { data } }) =>
                data.map((carVersion) => VehicleVersion.fromJson(carVersion))
            )

    getCoverageSummary = (type, year, brand, carVersion) =>
        axios
            .get(
                `${API_URL}/multi-quoter/${type}/summary?year=${year}&brand=${brand}&model=${carVersion}`
            )
            .then((response) => CarCoverageSummary.fromJson(response.data))

    buildQuote = (quoteParams) =>
        axios.post(`${API_URL}/multi-quotes`, quoteParams).then(({ data }) => {
            const {
                data: { id: multiquoterId },
            } = data
            return multiquoterId
        })

    static async requiringCoverages(id, onMessage) {
        await RequiringCoveragesService.requireCoverages(id, (response) => {
            onMessage(response)
        })
    }

    showMultiquote = (id) =>
        axios.get(`${API_URL}/multi-quotes/${id}`).then(({ data }) => {
            const { multiQuoteRequestStatus } = data
            if (multiQuoteRequestStatus === 'running') {
                return { multiQuoteRequestStatus }
            }
            const clusterArray = data.coverages.map((cluster) => VehicleCluster.fromJson(cluster))
            const clusterCustomer = ClusterCustomer.fromJson(data.data.customer)

            return { clusterArray, clusterCustomer, multiQuoteRequestStatus }
        })

    sendCoveragesEmail = (coveragesEmailData) =>
        axios
            .post(`${API_URL}/multi-quoter/send-email`, coveragesEmailData)
            .then((response) => response)

    getCompanies = () =>
        new Promise((resolve) => {
            resolve(this.companiesJson.map((company) => AdvancedConfigOption.fromJson(company)))
        })

    getLocalities = (postalCode) =>
        axios.get(`${API_URL}/locations?postcode=${postalCode}`).then(({ data }) => {
            const provinces = []
            data.data.map((locality) => {
                const findIndex = provinces.findIndex((province) => {
                    return province.value === locality.province.name
                })
                const localityId = locality.id
                const localityName = locality.name
                const provinceId = locality.province.id
                const provinceName = locality.province.name
                if (provinces.length < 1 || findIndex === -1) {
                    provinces.push(
                        Province.fromJson(provinceId, provinceName, [
                            SelectOption.fromJson({ id: localityId, name: localityName }),
                        ])
                    )
                } else {
                    provinces[findIndex].addLocality(
                        SelectOption.fromJson({ id: localityId, name: localityName })
                    )
                }
                return true
            })
            return provinces
        })

    getGenderOptions = () =>
        new Promise((resolve) => {
            resolve(this.genderJson.map((gender) => gender))
        })

    getInsuranceCompanies = () => {
        return axios
            .get(`${API_URL}/multi-quoter/insurance-companies`)
            .then(({ data }) => data.data.map((insuranceCompany) => insuranceCompany))
    }

    getClusters = () => {
        return axios
            .get(`${API_URL}/multi-quoter/clusters`)
            .then(({ data: { data } }) =>
                data.map((cluster) => new VehicleCluster(cluster.code, cluster.name))
            )
    }

    createQuoteCustomer = (quoteCustomerData) =>
        axios.post(`${API_URL}/quote/customer`, quoteCustomerData).then((response) => response)

    downloadPdf = (params) =>
        axios
            .get(`${API_URL}/multi-quoter/download`, {
                params,
            })
            .then((response) => {
                const pdf = response.data.data
                const linkSource = `data:application/pdf;base64,${pdf.content}`
                const downloadLink = document.createElement('a')
                downloadLink.href = linkSource
                downloadLink.download = pdf.filename
                downloadLink.click()
            })

    reQuote = (multiQuoteId, localityId, commissionType) => {
        const params = {}
        if (localityId) params.locality = localityId
        if (commissionType) params.commissionType = commissionType

        return axios
            .post(`${API_URL}/multi-quotes/${multiQuoteId}/clone`, params)
            .then((response) => response.data)
    }

    fromStorage() {
        return this
    }
}

export default QuoteCreationService
