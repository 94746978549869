import { observable, makeObservable, action } from 'mobx'
import InputStore from 'stores/InputStore'
import DateInputStore from 'stores/DateInputStore'
import { REQUIRED, REQUIRED_BIRTH_DATE, REQUIRED_DNI } from 'util/validationMapping'

class Driver {
    constructor() {
        this.identityNumber = new InputStore(REQUIRED_DNI)
        this.birthDate = new DateInputStore(REQUIRED_BIRTH_DATE)
        this.fullName = new InputStore(REQUIRED)
        this.isInsured = false

        makeObservable(this, {
            // observables
            identityNumber: observable,
            birthDate: observable,
            fullName: observable,
            isInsured: observable,
            // actions
            setIdentityNumber: action,
            setBirthDate: action,
            setFullName: action,
            setIsInsured: action,
        })
    }

    setIdentityNumber(value) {
        this.identityNumber.setValue(value)
    }

    setBirthDate(value) {
        this.birthDate.setValue(value)
    }

    setFullName(value) {
        this.fullName.setValue(value)
    }

    setIsInsured(value) {
        this.isInsured = value
    }

    clearErrors() {
        this.identityNumber?.clearError()
        this.birthDate?.clearError()
        this.fullName?.clearError()
    }

    async validate() {
        await this.identityNumber?.validate()
        await this.birthDate?.validate()
        await this.fullName?.validate()
    }

    json() {
        return {
            identityNumber: this.identityNumber?.value,
            birthDate: this.birthDate?.value ? this.birthDate?.value?.format("YYYY/MM/DD") : '',
            fullName: this.fullName?.value,
            isInsured: this.isInsured
        }
    }

    fromAPI(data) {
        this.setIdentityNumber(data?.identityNumber)
        this.setBirthDate(data?.birthDate)
        this.setFullName(data?.fullName)
        this.isInsured = data?.isInsured

        setTimeout(() => this.clearErrors(), 1)
    }
}

export default Driver
