import MetaTitle from 'presentation/MetaTitle'
import { useTranslation } from 'react-i18next'
import ToolsIcon from 'mdi-react/ToolsIcon'
import AppLogo from 'presentation/AppLogo'
import styles from './error503.module.scss'

const Error503 = () => {
    const { t } = useTranslation('appError')

    return (
        <>
            <MetaTitle title={t('pageTitles:notFound')} />
            <div className={styles.container}>
                <div className={styles.logo}>
                    <AppLogo />
                </div>
                <div className={styles.iconContainer}>
                    <ToolsIcon className={styles.icon} />
                </div>
                <div className={styles.title}>Error 503</div>
                <div className={styles.note}>{t('siteOnManteinance')}</div>
            </div>
        </>
    )
}

export default Error503
