import { useTranslation } from 'react-i18next'
import styles from './appRefresh.module.scss'

const AppRefresh = () => {
    const { t } = useTranslation('appRefresh')

    return (
        <div className={styles.container}>
            <div className={styles.error}>
                <h1 className={styles.title}>{t('title')}</h1>
                <div className={styles.message}>{t('message')}</div>
                <div>
                    <button
                        type="button"
                        onClick={() => window.location.reload()}
                        className={styles.button}
                    >
                        {t('buttonLabel')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AppRefresh
