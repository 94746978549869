import axios from 'axios'
import { API_URL } from 'services/config'

class BadgesService {
    getBadges = (params) =>
        axios
            // .get(`${API_URL}/notifications/count-unread`, { params })
            .get(`${API_URL}/badges`, { params })
            .then(
                ({
                    data: {
                        data: {
                            unreadNotifications: notificationsCountUnread,
                            unreadCommunications: comunicationsCountUnread,
                        } = {},
                    } = {},
                }) => ({ notificationsCountUnread, comunicationsCountUnread })
            )
}

export default BadgesService
