import AsyncStore from 'stores/AsyncStore'
import InputStore from 'stores/InputStore'
import { validateCaptcha } from 'react-simple-captcha';
import { action, observable, makeObservable, runInAction, computed } from 'mobx'

import InspectionPhoto from 'models/InspectionPhoto';
import CustomerSelfManagementService from 'services/CustomerSelfManagementService'
import { CAPTCHA, EMAIL, LICENSE_PLATE, REQUIRED_DNI_CUIL } from 'util/validationMapping'

export const INSPECTION_TAG = {
    FRONT: "front",
    REAR: "rear",
    LEFT_SIDE: "leftSide",
    RIGHT_SIDE: "rightSide",
    ROOF: "roof",
    VTV: "vehicleTechnicalVerification",           
    SPARE_WHEEL: "spareTire",
    VEHICLE_REGISTRATION: "vehicleTitle",
    WINDSHIELD_INTERIOR: "insideFrontWindshield",
    NON_ROLLING_CERTIFICATE: "nonRollingCertificate",
    ODOMETER: "odometer",
}

export const INSPECTION_STATUS = {
    PENDING: "pending", 
    IN_REVIEW: "inReview",
    FINISHED: "finished",
    REJECTED: "rejected",
    CANCELED: "canceled"
}

export const CUSTOMER_SELF_MANAGEMENT_AMENDMENT_TYPES = {
    PAYMENT_CHANGES: {
        id: "paymentChanges",
        value: "Cambiar forma de pago"
    },
    POLICY_CHANGES: {
        id: "policyChanges",
        value: "Modificar datos de póliza"
    }
}

export const CUSTOMER_SELF_MANAGEMENT_STEPS = {
    DOCUMENTS: "Mi seguro",
    PAYMENT_METHOD: "Cambiar forma de pago",
    POLICY_DATA: "Modificar datos de póliza",
    CONTACT: "Consultas y pedidos",
    ACCIDENT: "Siniestros",
    INSPECTIONS: "Inspección",
    CLIENT: "Cliente"
}

export const CUSTOMER_SELF_MANAGEMENT_DOCUMENTS = {
    POLICY: "poliza",
    MERCOSUR: "mercosur",
    PROVISIONAL: "provisorio",
    CIRCULATION: "circulacion",
    AMENDMENT: "endoso",
}

export const CUSTOMER_SELF_MANAGEMENT_SOCIAL_NETWORKS = {
    FACEBOOK: "facebook",
    YOUTUBE: "youtube",
    LINKEDIN: "linkedin",
    INSTAGRAM: "instagram"
}

class CustomerSelfManagementStore extends AsyncStore {
    constructor() {
        super()
        this.customerSelfManagementService = new CustomerSelfManagementService() 

        this.platforms = []
        this.loadingPlatforms = true

        this.platform = null
        this.metatitle = null
        this.logo = null
        this.contactInfo = null
        this.links = []
        this.colors = {
            primary: "#4dd642",     // PASAP colors
            secondary: "#2d3339"
        }
        
        this.step = null

        this.identityNumber = new InputStore(REQUIRED_DNI_CUIL)
        this.captcha = new InputStore(CAPTCHA)
        this.plate = new InputStore(LICENSE_PLATE)

        this.policyId = null
        this.insuredName = null
        this.insuranceCompany = null
        this.asset = null
        this.validFrom = null
        this.documents = []
        
        this.inspection = {
            id: null,
            status: { id: null, value: ""},
            comment: "",
            files: [],
            history: []
        }

        this.inspectionFiles = {}
        this.email = new InputStore(EMAIL)

        this.makeObservables()
    }

    setPlatforms(value) {
        this.platforms = value
        this.setLoadingPlatforms(false)
    }

    setLoadingPlatforms(value) {
        this.loadingPlatforms = value
    }

    setPlatform(value) {
        this.platform = value
    }

    setMetatitle(value) {
        this.metatitle = value
    }

    setLogo(value) {
        this.logo = value
    }

    setContactInfo(value) {
        this.contactInfo = value
    }

    setLinks(value) {
        this.links = value
    }

    setColors(value) {
        this.colors = value
    }

    setStep(value) {
        this.step = value
    }

    setIdentityNumber(value) {
        this.identityNumber.setValue(value)
    }

    setCaptcha(value) {
        this.captcha.setValue(value)
    }

    setPlate(value) {
        this.plate.setValue(value)
    }

    setPolicyData(value) {
        this.policyId = value?.id
        this.insuredName = value?.insuredName
        this.insuranceCompany = value?.insuredCompany
        this.asset = value?.asset
        this.validFrom = value?.validFrom
        this.documents = value?.documents
    }

    setInspection(value) {
        this.inspection = {
            id: value?.id,
            status: value?.status,
            comment: value?.comment,
            files: value?.files,
            history: value?.history
        }

        this.insuredName = value?.policy?.insuredFullName
        this.insuranceCompany = value?.policy?.insuredCompany
        this.asset = value?.policy?.asset
        this.validFrom = value?.policy?.validFrom
        this.email.setValue(value?.email)
    }

    setInspectionFiles(value) {
        this.inspectionFiles = value
    }

    setEmail(value) {
        this.email.setValue(value)
    }

    async listPlatforms() {
        this.preRequest(this.listPlatforms)

        try {
            const platforms = await Promise.race([
                this.customerSelfManagementService.getPlatforms(),
                new Promise((_, reject) => setTimeout(() => reject(new Error()), 2000))
            ]);

            runInAction(() => {
                this.setPlatforms(platforms)
                this.onSuccessRequest()
            })

            return true
        } catch (error) {
            this.setLoadingPlatforms(false)
            this.onErrorRequest(error?.message ? error?.message : 'Error inesperado, intente nuevamente')
            return false
        }
    }

    async checkCaptcha() {
        this.preRequest(this.checkCaptcha)
        await this.captcha?.validate() 

        if (this.captcha.error) {
            this.finishRequest()
            return false
        }

        try {
            if (!validateCaptcha(this.captcha?.value)) {
                throw new Error()
            }
            
            runInAction(() => {
                this.onSuccessRequest()
            })
            
            return true
        } catch (error) {
            this.setCaptcha('')
            this.onErrorRequest('El código ingresado no coincide')
            return false
        }
    }

    async checkIdentityNumber() {
        this.preRequest(this.checkIdentityNumber)
        await this.identityNumber?.validate() 

        if (this.identityNumber.error) {
            this.finishRequest()
            return false
        }

        try {
            await this.customerSelfManagementService.checkCustomer(this.identityNumber?.value, this.metatitle)

            runInAction(() => {
                this.onSuccessRequest()
            })

            return true
        } catch (err) {
            this.onErrorRequest(err?.message ? err?.message : 'Error inesperado, intente nuevamente')
            return false
        }
    }

    async checkUserPolicies() {
        this.preRequest(this.checkUserPolicies)
        await this.plate?.validate()  

        if (this.plate.error) {
            this.finishRequest()
            return false
        }

        try {
            const customer = { identityNumber: this.identityNumber?.value, plate: this.plate?.value }
            const { data: policyData } = await this.customerSelfManagementService.getPolicy(customer, this.metatitle)

            runInAction(() => {
                this.setPolicyData(policyData)
                this.onSuccessRequest()
            })

            return true
        } catch (error) {
            this.onErrorRequest(error?.message ? error?.message : 'Error inesperado, intente nuevamente')
            return false
        }
    }

    async getInspectionFromCustomer() {
        this.preRequest(this.getInspectionFromCustomer)
        await this.plate?.validate()  

        if (this.plate.error) {
            this.finishRequest()
            return false
        }

        try {
            const customer = { identityNumber: this.identityNumber?.value, plate: this.plate?.value }
            const { data: inspectionData } = await this.customerSelfManagementService.getInspectionId(customer)

            runInAction(() => {
                this.onSuccessRequest()
            })

            return inspectionData?.id
        } catch (error) {
            this.onErrorRequest(error?.message ? error?.message : 'Error inesperado, intente nuevamente')
            return false
        }
    }

    async getInspectionFromPolicy(policyId) {
        this.preRequest(this.getInspectionFromPolicy) 

        try {
            const { data: inspectionData } = await this.customerSelfManagementService.getInspectionId({ policyId })

            runInAction(() => {
                this.onSuccessRequest()
            })

            return inspectionData?.id
        } catch (error) {
            this.onErrorRequest(error?.message ? error?.message : 'Error inesperado, intente nuevamente')
            return false
        }
    }

    async getInspection(id) {
        this.preRequest(this.getInspection)

        try {
            const { data: inspectionData } = await this.customerSelfManagementService.getInspection(id)

            runInAction(() => {
                this.setInspection(inspectionData)
                this.getInspectionFiles()
                this.onSuccessRequest()
            })

            return true
        } catch (error) {
            this.onErrorRequest(error?.message ? error?.message : 'Error inesperado, intente nuevamente')
            this.resetInspection()
            return false
        }
    }

    async sendInspection() {
        this.preRequest(this.sendInspection)
        this.clearErrors()
        await this.email?.validate() 

        if (this.email.error) {
            this.finishRequest()
            return false
        }

        try {
            const uploadInspection = this.json()
            await this.customerSelfManagementService.uploadInspection(uploadInspection)

            runInAction(() => {
                this.onSuccessRequest()
            })

            return true
        } catch (err) {
            this.onErrorRequest(err?.message ? err?.message : 'Error inesperado, intente nuevamente')
            return false
        }
    }

    getInspectionFiles() {
        const inspectionFilesObject = {};
      
        if (this.inspection?.status?.id === INSPECTION_STATUS.PENDING) {
            const inspectionFiles = this.inspection?.files?.map(file => file.tag);
            inspectionFiles.forEach(tag => {
                inspectionFilesObject[tag.id] = new InspectionPhoto();
                inspectionFilesObject[tag.id].tag = { id: tag.id, value: tag.value };
            });
        }

        if (this.inspection?.status?.id === INSPECTION_STATUS.REJECTED) {
            const rejectedFiles = this.inspection?.files?.filter(file => file?.fileStatus?.id === INSPECTION_STATUS.REJECTED);
            rejectedFiles.forEach(file => {
                inspectionFilesObject[file.tag.id] = new InspectionPhoto();
                inspectionFilesObject[file.tag.id].tag = { id: file.tag.id, value: file.tag.value };
            });
        }

        if (this.inspection?.status?.id === INSPECTION_STATUS.FINISHED) {
            this.inspection?.files?.forEach(fileObj => {
                inspectionFilesObject[fileObj?.tag?.id] = {
                    file: fileObj?.file,
                    fileStatus: fileObj?.fileStatus,
                    tag: fileObj?.tag
                };
            });
        }

        this.setInspectionFiles(inspectionFilesObject)
        return inspectionFilesObject;
    }

    get shouldSendInspection() {
        return Object.keys(this.inspectionFiles).every(tag => {
            const file = this.inspectionFiles[tag];
            return file.file?.error !== true && file.file?.file !== null;
        });
    }

    setPlatformByPath(path) {
        this.platforms.forEach(platform => {
            const platformRegex = new RegExp(`/${platform?.name}(/|$)`);
            if (platformRegex.test(path)) {
                this.setPlatform(platform?.name);
                this.setMetatitle(platform?.metatitle);
                this.setLogo(platform?.logo);
                this.setContactInfo(platform?.contact);
                this.setLinks(platform?.links);
                this.setColors(platform?.colors);
            }
        });
    }

    resetInspection() {
        this.setInspection({
            id: null,
            status: null,
            comment: "",
            files: [],
            history: []
        })
    }

    resetStore() {
        this.setIdentityNumber(null)
        this.setCaptcha(null)
        this.setPlate(null)
        this.setPolicyData({
            policyId: null,
            insuredName: null,
            insuranceCompany: null,
            assetDescription: null,
            validFrom: null,
            documents: []
        })
        this.setInspection({
            id: null,
            status: null,
            comment: "",
            files: [],
            history: []
        })
        this.setInspectionFiles([])
        this.setStep(null)
        this.clearErrors()
    }

    clearErrors() {
        this.identityNumber?.clearError()
        this.captcha?.clearError()
        this.plate?.clearError()
        this.clearError()
    }

    json() {
        const files = Object.keys(this.inspectionFiles).map(key => {
            return {
                tag: key,
                file: this.inspectionFiles[key].file.fileBase64
            }
        })

        return {
            inspectionId: this.inspection?.id,
            email: this.email?.value,
            files
        }
    }

    makeObservables() {
        makeObservable(this, {
            // observables
            platforms: observable,
            loadingPlatforms: observable,
            platform: observable,
            metatitle: observable,
            logo: observable,
            contactInfo: observable,
            links: observable,
            colors: observable,
            step: observable,
            identityNumber: observable,
            captcha: observable,
            plate: observable,
            policyId: observable,
            insuredName: observable,
            insuranceCompany: observable,
            asset: observable,
            validFrom: observable,
            documents: observable,
            inspection: observable,
            inspectionFiles: observable,
            email: observable,
            // actions
            listPlatforms: action,
            setPlatform: action,
            checkCaptcha: action,
            checkIdentityNumber: action,
            setCaptcha: action,
            checkUserPolicies: action,
            getInspectionFromCustomer: action,
            getInspectionFromPolicy: action,
            getInspection: action,
            sendInspection: action,
            getInspectionFiles: action,
            setPlatformByPath: action,
            resetInspection: action,
            resetStore: action,
            clearErrors: action,
            // computed
            shouldSendInspection: computed,
        })
    }
}

export default CustomerSelfManagementStore
