import Broker from 'models/Broker'
import PermissionsDto from 'dto/PermissionsDto'

class BrokerDtoAPI {
    static fromJson({ permissions: PermissionsFromAPI, ...props }) {
        const permissions = PermissionsDto.fromJson(PermissionsFromAPI)

        return Broker.fromJson({ ...props, permissions })
    }
}

export default BrokerDtoAPI
