import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AppLogo from 'presentation/AppLogo'
import PlayStoreIco from './PlayStoreIco.svg'
import AppleStoreIco from './AppleStoreIco.svg'
import styles from './mobileAppSplash.module.scss'

const MobileAppSplash = () => {
    const { t } = useTranslation('common')

    const handleOnClickPlayStore = useCallback(() =>
        window.location.replace('https://play.google.com/store/apps/details?id=ar.com.pasap')
    )

    const handleOnClickAppleStore = useCallback(() =>
        window.location.replace('https://apps.apple.com/us/app/pasap/id1513244843')
    )

    return (
        <div className={styles.container}>
            <div className={styles.responsiveHeader}>
                <div className={styles.pasapLogo}>
                    <AppLogo />
                </div>
            </div>
            <div className={styles.title}>{t('downloadMobileApp')}</div>
            <div className={styles.description}>{t('downloadMobileAppDescription')}</div>
            <div className={styles.appStoresImages}>
                <img
                    className={styles.storeImage}
                    src={PlayStoreIco}
                    onClick={handleOnClickPlayStore}
                    alt=""
                />
                <img
                    className={styles.storeImage}
                    src={AppleStoreIco}
                    onClick={handleOnClickAppleStore}
                    alt=""
                />
            </div>
        </div>
    )
}

export default MobileAppSplash
