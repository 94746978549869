class VehicleVersion {
    constructor(id, infoAutoCode, name, brand, group) {
        const { name: modelName = '' } = group || {}
        const version = name?.toLowerCase().replace(modelName.toLowerCase(), '').trim()

        this.id = id
        this.infoAutoCode = infoAutoCode
        this.name = version?.length > 0 ? version : name
        this.brand = brand
        this.group = group
    }

    static fromJson({ id, infoAutoCode, name, brand, group }) {
        return new VehicleVersion(id, infoAutoCode, name, brand, group)
    }
}

export default VehicleVersion
