import { action, observable, computed, makeObservable } from 'mobx'
import Validation from 'models/Validation'
import { NOVALIDATION } from 'util/validationMapping'

class FileInputStore {
    file = null
    fileBase64 = null
    fileUrl = null
    fileSelected = false
    error = false
    errorMessage = ''

    constructor({
        id,
        validationType = NOVALIDATION,
        acceptedTypeList,
        maxFileSize = Infinity,
        supportedFormats = '',
    }) {
        this.setId(id)
        this.fileReader = null
        this.validation = new Validation(validationType, { validateField: 'fileBase64' })
        this.acceptedTypeList =
            acceptedTypeList == null ? null : String(acceptedTypeList).split(',')
        this.maxFileSize = maxFileSize
        this.supportedFormats = supportedFormats
        this.makeObservables()
    }

    get accept() {
        if (Array.isArray(this.acceptedTypeList)) {
            return this.acceptedTypeList.join(', ')
        }

        return ''
    }

    get processedRequestErrors() {
        const { error, errorMessage } = this

        if (error) {
            return [errorMessage]
        }

        return []
    }

    setId(id) {
        this.id = id
    }

    setUrl(url) {
        this.fileUrl = url
    }

    setAcceptedTypeList(acceptedTypeList) {
        this.acceptedTypeList = acceptedTypeList
    }

    setMaxFileSize(maxFileSize) {
        this.maxFileSize = maxFileSize
    }

    setFileSelected(selected) {
        this.fileSelected = selected
    }

    setError(errorMessage = '') {
        this.error = true
        this.errorMessage = errorMessage
    }

    clearError() {
        this.error = false
        this.errorMessage = ''
    }

    validateFile({ type: acceptMime }) {
        if (this.acceptedTypeList == null || acceptMime == null) {
            return true
        }

        const [fileType, fileSubtype] = acceptMime.trim().split('/')

        const response = this.acceptedTypeList.find((type) => {
            const acceptMimeType = type

            if (acceptMimeType === 'application/octet-stream') {
                return true
            }

            const [acceptedType, acceptedSubtype] = acceptMimeType.trim().split('/')

            if (
                acceptedType === fileType &&
                (acceptedSubtype === '*' || acceptedSubtype === fileSubtype)
            ) {
                return true
            }

            return false
        })

        if (response != null) {
            return true
        }

        this.setError('Invalid file type')

        return false
    }

    selectFile(file) {
        this.clearError()

        this.setFile(file)
        this.setFileSelected(true)
        this.fileReader = new FileReader()

        if (!this.validateFile(file)) {
            return
        }

        this.fileReader.readAsDataURL(this.file)

        this.fileReader.onload = ({ target: { result } }) => {
            if (!this.checkFileSize()) {
                this.setFileBase64(null)
                return
            }

            this.setFileBase64(result)
        }
    }

    unselectFile() {
        this.setFile(null)
        this.setFileBase64(null)
        this.setFileSelected(false)
        this.fileReader = null
    }

    setFile(file = {}, base64 = null, url = null) {
        this.file = file
        this.setFileBase64(base64)
        this.setUrl(url)
        this.setFileSelected(file != null && (base64 != null || url != null))
    }

    setFileBase64(base64) {
        this.fileBase64 = base64
    }

    checkFileSize() {
        const { size } = this.file || {}

        if (size != null && size > this.maxFileSize) {
            this.setError('Invalid file size')

            return false
        }

        return true
    }

    async validate() {
        await this.validation.validate(this)
    }

    makeObservables() {
        makeObservable(this, {
            // observables
            id: observable,
            file: observable,
            fileBase64: observable,
            fileUrl: observable,
            fileSelected: observable,
            error: observable,
            errorMessage: observable,
            acceptedTypeList: observable,
            // actions
            setId: action,
            setAcceptedTypeList: action,
            setMaxFileSize: action,
            setFileSelected: action,
            setError: action,
            clearError: action,
            validateFile: action,
            selectFile: action,
            setFile: action,
            setFileBase64: action,
            checkFileSize: action,
            validate: action,
            // computed
            accept: computed,
            processedRequestErrors: computed,
        })
    }
}

export default FileInputStore
